import { auth } from "../Firebase";

const Utils = {
  /** Update on server as well, when changed */
  validateEmail: (email) => {
    return email && /\S+@\S+\.\S+/.test(email);
  },

  calculatePercentage: (percent, totalValue) => {
    return (percent / 100) * totalValue;
  },

  /** Makes a json request to the cloud functions, returns an object with promise and cancel option */
  request: async (url, method = "POST", data) => {
    const token = await auth.currentUser.getIdToken();
    let requestObj = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        lang: "en",
      },
    };
    if (method.toLowerCase() === "post" && data) {
      requestObj.body = JSON.stringify(data);
    }
    let response = await fetch(url, requestObj);
    let json = await response.json();
    if (response.status === 200) {
      return json;
    } else {
      throw new Error(json.message);
    }
  },

  capitalizeFirstLetter: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  check_lat_lon: (lat, lon) => {
    const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    const regexLon =
      /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
    let validLat = regexLat.test(lat);
    let validLon = regexLon.test(lon);
    return validLat && validLon;
  },
  convertToCSV: (data) => {
    let headers = [];
    let csvRows = [];

    // Get all unique keys from objects as headers
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!headers.includes(key)) {
          headers.push(key);
        }
      });
    });

    // Build CSV rows
    data.forEach((item) => {
      let csvRow = [];

      headers.forEach((header) => {
        let value = item[header];

        // If value is an object or array, stringify it
        if (typeof value === "object" && value !== null) {
          value = JSON.stringify(value);
        }

        // Replace commas in value with spaces
        if (typeof value === "string") {
          value = value.replace(/,/g, " ");
        }

        csvRow.push(value || "");
      });

      csvRows.push(csvRow.join(","));
    });

    // Add headers to the CSV string
    let csvString = headers.join(",") + "\n";

    // Add data rows to the CSV string
    csvString += csvRows.join("\n");

    return csvString;
  },
};

export default Utils;
