/* eslint-disable eqeqeq */
import React from "react";
import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import Select from "react-select";
import Switch from "react-switch";
import { ColorExtractor } from "react-color-extractor";

class AdsAddUpdate extends ParentComponent {
  constructor(props) {
    super(props);
    const { adKey } = props.match.params;
    this.state = {
      data: {
        active: true,
      },
      loading: true,
      adKey,
      colors: [],
      types: [],
      industries: [],
    };
    this.colRef = firebase.firestore().collection("ads");
  }

  business_types = [
    { label: "Sole proprietorship", value: "soleProprietorship" },
    { label: "Partnership", value: "partnership" },
    {
      label: "Limited liability company (LLC)",
      value: "limitedLiabilityCompany",
    },
    { label: "Corporation", value: "corporation" },
    { label: "Non-profit organization", value: "nonProfitOrganization" },
    { label: "Cooperative", value: "cooperative" },
    { label: "Franchise", value: "franchise" },
    { label: "Joint venture", value: "jointVenture" },
    { label: "Holding company", value: "holdingCompany" },
    { label: "State-owned enterprise", value: "stateOwnedEnterprise" },
  ];

  business_industries = [
    { label: "Technology", value: "technology" },
    { label: "Finance", value: "finance" },
    { label: "Healthcare", value: "healthcare" },
    { label: "Retail", value: "retail" },
    { label: "Manufacturing", value: "manufacturing" },
    { label: "Food and Beverage", value: "foodAndBeverage" },
    { label: "Energy", value: "energy" },
    { label: "Transportation", value: "transportation" },
    { label: "Construction", value: "construction" },
    { label: "Professional Services", value: "professionalServices" },
    { label: "Hospitality", value: "hospitality" },
    { label: "Agriculture", value: "agriculture" },
    { label: "Media and Entertainment", value: "mediaAndEntertainment" },
    { label: "Education", value: "education" },
    { label: "E-commerce", value: "eCommerce" },
    { label: "Telecommunications", value: "telecommunications" },
    { label: "Consumer Goods", value: "consumerGoods" },
    { label: "Environmental Services", value: "environmentalServices" },
    { label: "Real Estate", value: "realEstate" },
  ];

  async componentDidMount() {
    let { adKey, data, types, industries } = this.state;

    if (adKey) {
      this.getTypes = await this.colRef.doc("types").get();
      if (this.getTypes?.exists) {
        const data = this.getTypes?.data()?.types;
        let values = new Set(this.business_types.map((d) => d.value));
        let merged = [
          ...this.business_types,
          ...data.filter((d) => !values.has(d.value)),
        ];
        types = merged;
        this.updateState({ types });
      }
      this.getIndustry = await this.colRef.doc("industries").get();
      if (this.getIndustry?.exists) {
        const data = this.getTypes?.data()?.industries;
        let values = new Set(this.business_types.map((d) => d.value));
        let merged = [
          ...this.business_industries,
          ...data.filter((d) => !values.has(d.value)),
        ];
        industries = merged;
        this.updateState({ industries });
      }
      this.unsub = this.colRef.doc(adKey).onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        data = snap.data();
        data.type = this.business_types.filter(
          ({ value }) => value === data.type
        );
        this.updateState({ data, loading: false });
      });
    }
    this.updateState({ loading: false });
  }

  async componentWillUnmount() {
    if (this.unsub) {
      this.unsub();
    }
  }

  updateState = (obj, callback) => {
    return this.setState(obj, callback);
  };

  showFilePicker = () => {
    this.fileEle.click();
  };

  updatefield = async (key, e) => {
    const { data } = this.state;
    const { socialProfiles } = data;
    if (key === "active") {
      return this.updateState({ data: { ...data, [key]: e } });
    }
    if (key == "facebook" || key == "twitter" || key == "linkedin") {
      return this.updateState({
        data: {
          ...data,
          socialProfiles: { ...socialProfiles, [key]: e.target.value },
        },
      });
    }
    if (key == "type") {
      return this.updateState({ data: { ...data, [key]: e?.value } });
    }
    if (key === "businessLogo") {
      const file = e.target.files && e.target.files[0] ? e.target.files[0] : "";
      const image_ = file;
      const objURL = URL.createObjectURL(file);
      return this.updateState({
        data: { ...data, businessLogo: objURL },
        image_,
      });
    }
    return this.updateState({ data: { ...data, [key]: e.target.value } });
  };

  changeCoordinates = async (key, e) => {
    const { data } = this.state;
    const { coordinates } = data;
    this.updateState({
      data: { ...data, coordinates: { ...coordinates, [key]: e.target.value } },
    });
  };

  uploadLogoAndDownload = async (key, businessName, imageURL_) => {
    await firebase
      .storage()
      .ref(`images/ads`)
      .child(`${key}/${businessName}`)
      .put(imageURL_);
    const downloadURL = await firebase
      .storage()
      .ref(`images/ads`)
      .child(`${key}/${businessName}`)
      .getDownloadURL();
    return downloadURL;
  };

  /**
   * Data is validated once its come here`
   * @param {*} data
   */
  onSave = async () => {
    const { data, image_ } = this.state;
    if (Object.keys(data).length === 0) {
      return;
    }
    if (data) {
      this.showLoader();
      const { data } = this.state;
      const { businessName, key } = data;
      if (!key) {
        //then its new
        let key = firebase.firestore().collection("ads").doc().id;
        data.key = key;
      }
      if (image_) {
        let downloadURL = await this.uploadLogoAndDownload(
          data.key,
          businessName,
          image_
        );
        data.businessLogo = downloadURL;
        delete data.image_;
      }
      if (data?.coordinates) {
        const { latitude, longitude } = data.coordinates;
        data.coordinates = new firebase.firestore.GeoPoint(latitude, longitude);
      }
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      await this.colRef.doc(data.key).set(data, { merge: true });
      this.hideLoader();
      this.showSuccess("Ad placed on Map successfully");
      this.props.history.replace(this.parentPath);
    }
  };

  updateIndustries = (e) => {
    let { data } = this.state;
    if (e.length === 0) {
      this.updateState({
        data: { ...data, industries: [] },
      });
      return;
    }
    let industries = [];
    e.forEach((value) => {
      industries.push(value);
    });
    this.updateState({ data: { ...data, industries } });
  };

  setSelectedColor = (index) => {
    const { colors, data } = this.state;
    const borderColor = colors[index];
    return this.updateState({ active: index, data: { ...data, borderColor } });
  };

  renderSwatches = () => {
    const { colors, active } = this.state;

    return colors.slice(0, 4).map((color, i) => {
      return (
        <div
          key={i}
          className={"col s2 m-l-5 m-r-5"}
          style={{
            backgroundColor: color,
            width: 80,
            height: 80,
            borderRadius: 12,
            ...(active === i && {
              borderWidth: 3,
              borderColor: "#E10039",
              borderStyle: "solid",
            }),
          }}
          onClick={this.setSelectedColor.bind(this, i)}
        />
      );
    });
  };

  getColors = (imgColors) => {
    this.updateState({ colors: [...imgColors] });
  };

  render() {
    const { loading, data } = this.state;
    if (loading) {
      return null;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s5">
            <h5>Map Ads</h5>
            <p>You can add/edit Map Ads here</p>
          </div>
          <div className="col s3"></div>
        </div>
        <div className={"card"}>
          <div className={"card-content"}>
            <div className="d-flex flex-row align-items-center justify-content-space">
              <div>
                <div
                  style={{ width: "250px", height: "80%" }}
                  onClick={this.showFilePicker}
                >
                  {!this.state?.data?.businessLogo ? (
                    <div className="d-flex flex-column jcac border-gray p-10 pointer">
                      <big className="center-align d-block m-t-10">
                        Max 600 X 600 px
                      </big>
                      <big className="center-align d-block">
                        Large image takes time to load, so keep it optimal
                      </big>
                    </div>
                  ) : (
                    <ColorExtractor getColors={this.getColors}>
                      <img
                        className="mx-auto d-block"
                        src={this.state?.data?.businessLogo}
                        style={{ width: "350px", height: "85%" }}
                        alt="cover"
                      />
                    </ColorExtractor>
                  )}
                </div>
                <input
                  ref={(input) => (this.fileEle = input)}
                  type="file"
                  className="d-none"
                  onChange={this.updatefield.bind(this, "businessLogo")}
                />
                <div>
                  {data?.businessLogo && this.state.colors?.length !== 0 && (
                    <div className={"row"}>
                      <div className={"col s12"}>
                        <div
                          className={
                            "d-flex flex-row align-items-center justify-content-space m-t-20"
                          }
                        >
                          {this.renderSwatches()}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w60">
                <div className="input-field">
                  <div className="input-field m-t-20">
                    <input
                      value={this.state.data.businessName}
                      id="businessName"
                      type="text"
                      onChange={this.updatefield.bind(this, "businessName")}
                    />
                    <label
                      className={data?.businessName ? "active" : ""}
                      htmlFor={"businessName"}
                    >
                      Business Name
                    </label>
                  </div>
                </div>
                <div>
                  <span className={"black-text2 f-monospace font-bold"}>
                    Business Details
                  </span>
                </div>
                <div className="input-field">
                  <div className="input-field m-t-20">
                    <Select
                      isMulti={false}
                      onChange={this.updatefield.bind(this, "type")}
                      options={this.state.types}
                      name="type"
                      placeholder="Business Type"
                      value={
                        this.state.data.type &&
                        this.business_types.filter(function (option) {
                          return option.value === data.type;
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-field m-t-20">
                    <Select
                      isMulti={true}
                      onChange={this.updateIndustries}
                      options={this.state.industries}
                      name="industries"
                      placeholder="Industry"
                      value={this.state.data.type}
                    />
                  </div>
                </div>
                <span className={"black-text2 f-monispace"}>
                  Business Coordinates
                </span>
                <div className="d-flex flex-row align-items-center justify-content-space">
                  <div className="input-field w50">
                    <input
                      value={this.state.data?.coordinates?.latitude}
                      id="latitude"
                      type="text"
                      onChange={this.changeCoordinates.bind(this, "latitude")}
                    />
                    <label
                      className={data?.coordinates?.latitude ? "active" : ""}
                      htmlFor={"latitude"}
                    >
                      Latitude
                    </label>
                  </div>
                  <div className="input-field w50 row-minus">
                    <input
                      value={this.state.data?.coordinates?.longitude}
                      id="longitude"
                      type="text"
                      onChange={this.changeCoordinates.bind(this, "longitude")}
                    />
                    <label
                      className={data?.coordinates?.longitude ? "active" : ""}
                      htmlFor={"longitude"}
                    >
                      Longitude
                    </label>
                  </div>
                </div>
                <div className="input-field">
                  <input
                    value={this.state.data.businessWebsite}
                    id="businessWebsite"
                    type="text"
                    onChange={this.updatefield.bind(this, "businessWebsite")}
                  />
                  <label
                    className={data?.businessWebsite ? "active" : ""}
                    htmlFor={"businessWebsite"}
                  >
                    Business Website
                  </label>
                </div>
                <div className="input-field">
                  <textarea
                    value={this.state.data.description}
                    id="description"
                    className="materialize-textarea"
                    onChange={this.updatefield.bind(this, "description")}
                    maxLength={300}
                  />
                  <label
                    className={data?.description ? "active" : ""}
                    htmlFor={"description"}
                  >
                    Business About
                  </label>
                </div>
                <div className="input-field">
                  <div className="input-field m-t-20"></div>
                </div>
                <div className="input-field">
                  <div className="input-field m-t-20"></div>
                </div>
                <div className="row m-t-10 input-field">
                  <div className="col s10">
                    <label
                      className={
                        "f-monospace font-medium blue-grey-text text-darken-4"
                      }
                      htmlFor={"chapters"}
                    >
                      Active
                    </label>
                  </div>
                  <div className="col s2">
                    <Switch
                      checked={this.state.data?.active}
                      onChange={this.updatefield.bind(this, "active")}
                    />
                  </div>
                </div>
                <span
                  className={"font-16 font-medium blue-grey-text text-darken-4"}
                >
                  Social Profiles
                </span>
                <div className={"d-flex flex-row align-items-center"}>
                  <i className="material-icons m-r-10">info</i>
                  <small className={"small"}>
                    Please add only social media handle/username of the
                    account/page only.
                  </small>
                </div>
                <div
                  className={
                    "row d-flex flex-row align-items-center justify-content-space m-t-10"
                  }
                >
                  <span className={"col l4 text-dark-4"}>Facebook</span>
                  <div className="input-field col l8">
                    <input
                      value={this.state.data.socialProfiles?.facebook}
                      id="facebook"
                      type="text"
                      onChange={this.updatefield.bind(this, "facebook")}
                    />
                    <label
                      className={data?.socialProfiles?.facebook ? "active" : ""}
                      htmlFor={"facebook"}
                    >
                      Facebook
                    </label>
                  </div>
                </div>
                <div
                  className={
                    "row d-flex flex-row align-items-center justify-content-space m-t-10"
                  }
                >
                  <span className={"col l4 text-dark-4"}>Twitter</span>
                  <div className="input-field col l8">
                    <input
                      value={this.state.data?.twitter}
                      id="twitter"
                      type="text"
                      onChange={this.updatefield.bind(this, "twitter")}
                    />
                    <label
                      className={data?.socialProfiles?.twitter ? "active" : ""}
                      htmlFor={"twitter"}
                    >
                      twitter
                    </label>
                  </div>
                </div>
                <div
                  className={
                    "row d-flex flex-row align-items-center justify-content-space m-t-10"
                  }
                >
                  <span className={"col l4 text-dark-4"}>Linkedin</span>
                  <div className="input-field col l8">
                    <input
                      value={this.state.data?.linkedin}
                      id="linkedin"
                      type="text"
                      onChange={this.updatefield.bind(this, "linkedin")}
                    />
                    <label
                      className={data?.socialProfiles?.linkedin ? "active" : ""}
                      htmlFor={"linkedin"}
                    >
                      Linkedin
                    </label>
                  </div>
                </div>
                <div
                  className={
                    "row d-flex flex-row align-items-center justify-content-space m-t-10"
                  }
                >
                  <span className={"col l4 text-dark-4"}>Instagram</span>
                  <div className="input-field col l8">
                    <input
                      value={this.state.data?.instagram}
                      id="linkedin"
                      type="text"
                      onChange={this.updatefield.bind(this, "instagram")}
                    />
                    <label
                      className={data?.socialProfiles?.linkedin ? "active" : ""}
                      htmlFor={"instagram"}
                    >
                      Instagram
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary d-block mx-auto"
                    onClick={this.onSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdsAddUpdate;
