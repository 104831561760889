import React from "react";
import firebase from "../../lib/Firebase";
import { AuthContext } from "../../lib/AuthContext";
import ParentComponent from "../../lib/ParentComponent";

export default class AdTypes extends ParentComponent {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      industries: [],
      newdata: {},
      currentIndex: null,
      industry: null,
      loading: true,
    };
    this.industryRef = firebase.firestore().collection("ads").doc("industries");
  }

  async componentDidMount() {
    let $ = window.$;
    this.unsubscribe = this.industryRef.onSnapshot((snap) => {
      let industries;
      if (snap && snap.exists) {
        const data = snap?.data();
        if (data?.industries) {
          industries = data?.industries;
        }
      }

      this.updateState({ industries, loading: false }, () => {
        $(".tooltipped").tooltip({ outDuration: 0 });
      });
      $(".modal").modal();
    });
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  delete = async (item) => {
    if (item) {
      this.showLoader();
      await this.industryRef.update({
        types: firebase.firestore.FieldValue.arrayRemove(item),
      });
      this.hideLoader();
    }
    this.showSuccess("Industry deleted successfully");
  };

  updateValues = (key, e) => {
    const { industry = {} } = this.state;
    industry[key] = e.target.value;
    this.updateState({ industry });
  };

  updateNewValues = (key, e) => {
    let { newdata = {} } = this.state;
    newdata[key] = e.target.value;
    this.updateState({ newdata });
  };

  saveNew = async () => {
    let { newdata = {}, types } = this.state;
    let isExist = types.find((cat) => cat.value === newdata.value);
    if (isExist) {
      this.showError("Industry already exist");
      return;
    }
    if (newdata.value && newdata.label) {
      this.showLoader();
      await this.industryRef.set(
        { industries: firebase.firestore.FieldValue.arrayUnion(newdata) },
        { merge: true }
      );
      this.hideLoader();
      this.showSuccess("Industry added successfully");
    } else {
      this.showError("Please fill in all details");
    }
  };

  setCurrent = (index, type) => {
    this.setState({ currentIndex: index, type });
  };

  update = async () => {
    const { currentIndex, industry, industries } = this.state;
    let { value, label } = industry;
    let valid = true;
    if (!value && label) {
      valid = false;
    }
    if (!valid) {
      this.showError("Some fields are missing");
      return false;
    }
    industries[currentIndex] = industry;
    //for all those whose images has been changed, upload the image to storage
    this.showLoader();

    await this.industryRef.update({ industries });
    this.hideLoader();
    this.showSuccess(" updated successfully");
  };

  renderEditModal = () => {
    const { type } = this.state;
    return (
      <div className="modal-content">
        <h5>Editing Type</h5>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col s6">
            <div className="input-field">
              <input
                value={type?.value || ""}
                id="value"
                type="text"
                className="validate"
                onChange={this.updateValues.bind(this, "value")}
              />
              <label className={type?.value ? "active" : ""} htmlFor="value">
                Type Value
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={type?.label || ""}
                id="label"
                type="text"
                className="validate"
                onChange={this.updateValues.bind(this, "label")}
              />
              <label className={type?.label ? "active" : ""} htmlFor="label">
                Type Label
              </label>
            </div>
          </div>
        </div>
        <div className="text-right m-t-20">
          <button className="modal-action modal-close waves-effect waves-green btn-flat">
            Cancel
          </button>
          <button
            className="modal-close btn waves-effect waves-light btn-round blue"
            onClick={this.update}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  renderNewModal = () => {
    let { newdata } = this.state;
    return (
      <div className="modal-content">
        <h5>Add Type</h5>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col s6">
            <div className="input-field">
              <input
                value={newdata.value || ""}
                id="newvalue"
                type="text"
                className="validate"
                onChange={this.updateNewValues.bind(this, "value")}
              />
              <label
                className={newdata.value ? "active" : ""}
                htmlFor="newvalue"
              >
                Industry Value e.g: nonProfitOrganization
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={newdata.label || ""}
                id="newlabel"
                type="text"
                className="validate"
                onChange={this.updateNewValues.bind(this, "label")}
              />
              <label
                className={newdata.label ? "active" : ""}
                htmlFor="newlabel"
              >
                Industry Label e.g: Non Profit Organization
              </label>
            </div>
          </div>
        </div>
        <div className="text-right m-t-20">
          <button className="modal-action modal-close waves-effect waves-green btn-flat">
            Cancel
          </button>
          <button
            className="modal-close btn waves-effect waves-light btn-round blue"
            onClick={this.saveNew}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    Business Industries ({dataArray ? dataArray.length : 0})
                  </h5>
                </div>
              </div>
            </div>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Value</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading && dataArray.length === 0 && (
                  <tr>
                    <td colSpan="6">
                      No ndustries are found, consider adding a new type
                    </td>
                  </tr>
                )}
                {dataArray.map((val, i) => {
                  return (
                    <tr key={`${i}`} className={""}>
                      <td>{val?.value}</td>
                      <td>{val?.label}</td>
                      <td>
                        <i
                          data-position="top"
                          data-tooltip="Delete"
                          className="tooltipped material-icons p-5 pointer modal-trigger"
                          data-target="deletionconfirm"
                          onClick={this.setCurrent.bind(this, i, val)}
                        >
                          delete
                        </i>
                        <i
                          data-position="top"
                          data-tooltip="Edit"
                          className="tooltipped material-icons p-5 pointer modal-trigger"
                          data-target="editmodal"
                          onClick={this.setCurrent.bind(this, i, val)}
                        >
                          edit
                        </i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { industries, loading, industry } = this.state;
    if (loading) {
      return null;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s10">
            <h4>Business Industries</h4>
            <p className="small">This is list of business industries</p>
          </div>
          <div className="col s2 text-right">
            <button
              className="btn btn-primary d-block mx-auto modal-trigger"
              data-target="newmodal"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="row">{this.getTableData(industries)}</div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>
              You are sure to delete this category, you won't be able to undo
              it.
            </p>
            <div className="text-right m-t-20">
              <button className="modal-action modal-close waves-effect waves-green btn-flat">
                Cancel
              </button>
              <button
                className="modal-close btn waves-effect waves-light btn-round blue"
                onClick={this.delete.bind(this, industry)}
              >
                Yes, I'm Sure
              </button>
            </div>
          </div>
        </div>
        <div id="editmodal" className="modal">
          {this.renderEditModal()}
        </div>
        <div id="newmodal" className="modal">
          {this.renderNewModal()}
        </div>
      </div>
    );
  }
}
