import React from "react";
import firebase from "../../lib/Firebase";
import { AuthContext } from "../../lib/AuthContext";
import ParentComponent from "../../lib/ParentComponent";
import Select from "react-select";
import { MEMBER_SHIP } from "../../lib/Constants";

export default class WelcomeSliders extends ParentComponent {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      user: context.user,
      slideshow: {},
      newdata: {},
      totalslides: 0,
      currentKey: null,
      loading: true,
      languages: [],
    };
    this.slideshowRef = firebase.firestore().collection(`proslides`);
    this.storage = firebase.storage().ref("/proslides");
  }

  async componentDidMount() {
    let $ = window.$;
    this.unsubscribe = this.slideshowRef.doc("slides").onSnapshot((snap) => {
      let slideshow = {
        slides: [],
      };
      let totalslides = null;
      if (snap && snap.exists) {
        slideshow = snap.data();
        totalslides = slideshow.slides.length;
      }
      let loading = this.state.loading;
      this.updateState({ slideshow, totalslides, loading: false }, () => {
        $(".tooltipped").tooltip({ outDuration: 0 });
        if (loading) {
          $(".modal").modal();
        }
      });
    });
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  _options = [
    { value: MEMBER_SHIP.diamond, label: "Dim Diamond" },
    { value: MEMBER_SHIP.gold, label: "Dim Gold" },
  ];

  onValueChange = (val) => {
    let { newData } = this.state;
    let arr = [];
    val?.forEach((val) => {
      arr.push(val?.value);
    });
    this.updateState({ newData: { ...newData, membershipType: arr } });
  };

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  delete = async (key) => {
    let { slideshow } = this.state;
    let { slides } = slideshow;
    let index = slides.findIndex((s) => s.key === key);
    slides.splice(index, 1);
    slideshow.slides = slides;
    await this.slideshowRef.doc("slides").update(slideshow);
    this.updateState({ slideshow });
    this.showSuccess("Slide deleted successfully");
  };

  saveCurrent = (key) => {
    this.updateState({ currentKey: key });
  };

  updateImage = (key, e) => {
    let { slideshow } = this.state;
    let { slides } = slideshow;
    let index = slides.findIndex((s) => s.key === key);
    let slide = slides[index];
    slide.imageURL = URL.createObjectURL(e.target.files[0]);
    slide.image = e.target.files[0];
    this.updateState({ slideshow });
  };

  updateTitle = (key, langCode, e) => {
    let { slideshow } = this.state;
    let { slides } = slideshow;
    let index = slides.findIndex((s) => s.key === key);
    let slide = slides[index];
    if (!slide.title) {
      slide.title = {};
    }
    slide.title[langCode] = e.target.value;
    this.updateState({ slideshow });
  };

  updateOrdering = (key, e) => {
    let { slideshow } = this.state;
    let { slides } = slideshow;
    let index = slides.findIndex((s) => s.key === key);
    let slide = slides[index];
    slide.ordering = parseInt(e.target.value);
    this.updateState({ slideshow });
  };

  updateDefaultText = (key, e) => {
    let { slideshow } = this.state;
    let { slides } = slideshow;
    let index = slides.findIndex((s) => s.key === key);
    let slide = slides[index];
    slide.title = e.target.value;
    this.updateState({ slideshow });
  };

  updateNewValues = (key, e) => {
    let { newdata = {} } = this.state;
    if (key === "imageURL") {
      newdata[key] = URL.createObjectURL(e.target.files[0]);
      newdata.image = e.target.files[0];
    } else if (key === "ordering") {
      newdata[key] = e.target.value ? parseInt(e.target.value) : "";
    } else if (key === "membershipType") {
      console.log("key -> ", key);
      let arr = [];
      e?.forEach((val) => {
        arr.push(val?.value);
      });
      newdata[key] = arr;
    } else {
      newdata[key] = e.target.value;
    }
    this.updateState({ newdata });
  };

  saveNew = async () => {
    let { newdata = {}, slideshow } = this.state;
    if (
      newdata.title &&
      newdata.tagline &&
      newdata.membershipType &&
      newdata.imageURL &&
      newdata.ordering >= 0
    ) {
      let { slides } = slideshow;
      this.showLoader();
      let newKey = this.slideshowRef.doc().id;
      newdata.key = newKey;
      slides.push(newdata);
      for (var i = 0; i < slides.length; i++) {
        let { key, image } = slides[i];
        if (image) {
          await this.storage.child(key).put(image);
          let downloadURL = await this.storage.child(key).getDownloadURL();
          slides[i].imageURL = downloadURL;
          delete slides[i].image;
        }
      }
      let isdocExists = await this.slideshowRef.doc("slides").get();
      if (isdocExists?.exists) {
        await this.slideshowRef.doc("slides").update(slideshow);
      }
      await this.slideshowRef.doc("slides").set(slideshow);
      this.hideLoader();
      this.showSuccess("Slideshow added successfully");
    } else {
      this.showError("Please fill in all details");
    }
  };

  update = async () => {
    let { slideshow } = this.state;
    let { slides } = slideshow;
    let valid = true;
    slides.forEach((s) => {
      let { ordering } = s;
      if (!ordering || ordering < 0) {
        valid = false;
      }
    });
    if (!valid) {
      this.showError("Some fields are missing");
      return false;
    }
    //for all those whose images has been changed, upload the image to storage
    this.showLoader();
    for (var i = 0; i < slides.length; i++) {
      let { key, image } = slides[i];
      if (image) {
        await this.storage.child(key).put(image);
        let downloadURL = await this.storage.child(key).getDownloadURL();
        slides[i].imageURL = downloadURL;
        delete slides[i].image;
      }
    }
    await this.slideshowRef.doc("slides").update(slideshow);
    this.hideLoader();
    this.showSuccess("Slideshow updated successfully");
  };

  renderSingle = (slide) => {
    let { totalslides } = this.state;
    let { imageURL, ordering, key, title, tagline, membershipType } = slide;
    return (
      <div key={`item_slide${key}`} className="col l4">
        <div className="card">
          <div className="card-content p-t-5 p-r-5">
            <div className="text-right">
              {totalslides > 1 && (
                <i
                  data-position="top"
                  data-tooltip="Delete"
                  className="tooltipped material-icons p-5 pointer modal-trigger"
                  data-target="deletionconfirm"
                  onClick={this.saveCurrent.bind(this, key)}
                >
                  delete
                </i>
              )}
              <i
                data-position="top"
                data-tooltip="Edit"
                className="tooltipped material-icons p-5 pointer modal-trigger"
                data-target="editmodal"
                onClick={this.saveCurrent.bind(this, key)}
              >
                edit
              </i>
            </div>
            {imageURL && (
              <img
                src={imageURL}
                style={{ width: 80, height: 80 }}
                alt="slides"
              />
            )}
            {!imageURL && (
              <>
                <small className="center-align d-block m-t-10">
                  Max 600 X 600 px
                </small>
                <small className="center-align d-block">
                  Large image takes time to load, so keep it optimal
                </small>
              </>
            )}
            <h5 className="m-t-10">{`Title: ${title}`}</h5>
            <h6 className="m-t-10">{`Tagline: ${tagline}`}</h6>
            <h6 className="m-t-10">{`Membership: ${membershipType}`}</h6>
            <h6 className="m-t-10">Ordering: {ordering}</h6>
          </div>
        </div>
      </div>
    );
  };

  renderEditModal = () => {
    let { slideshow, currentKey } = this.state;
    let { slides = [] } = slideshow;
    let index = slides.findIndex((s) => s.key === currentKey);
    let current = index > -1 ? slides[index] : {};
    return (
      <div className="modal-content">
        <h5>Editing Slide</h5>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col s6">
            <div>
              {current.imageURL && (
                <img
                  className="mx-auto d-block"
                  src={current.imageURL}
                  style={{ width: 120, height: "auto", maxHeight: 120 }}
                  alt="slide"
                />
              )}
              <small className="center-align d-block m-t-10">
                Max 600 X 600 px
              </small>
              <small className="center-align d-block">
                Large image takes time to load, so keep it optimal
              </small>
            </div>
            <div className="file-field input-field">
              <div className="btn blue darken-1">
                <span>Select Image</span>
                <input
                  type="file"
                  onChange={this.updateImage.bind(this, currentKey)}
                />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate" type="text" />
              </div>
            </div>
            <div className="input-field m-b-20">
              <input
                value={current.ordering}
                id="ordering"
                type="number"
                className="validate"
                onChange={this.updateOrdering.bind(this, currentKey)}
              />
              <label
                className={current.ordering >= 0 ? "active" : ""}
                htmlFor="ordering"
              >
                Ordering
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={current.title || ""}
                id="title"
                type="text"
                className="validate"
                onChange={this.updateDefaultText.bind(this, currentKey)}
              />
              <label className={current.title ? "active" : ""} htmlFor="title">
                Title
              </label>
            </div>
            <p className="m-b-0">Title</p>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={current.title || ""}
                id="title"
                type="text"
                className="validate"
                onChange={this.updateDefaultText.bind(this, currentKey)}
              />
              <label className={current.title ? "active" : ""} htmlFor="title">
                Title
              </label>
            </div>
            <p className="m-b-0">Title</p>
          </div>
        </div>
        <div className="text-right m-t-20">
          <button className="modal-action modal-close waves-effect waves-green btn-flat">
            Cancel
          </button>
          <button
            className="modal-close btn waves-effect waves-light btn-round blue"
            onClick={this.update}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  renderNewModal = () => {
    let { newdata } = this.state;
    return (
      <div className="modal-content">
        <h5>Add Slide</h5>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col s6">
            <div>
              {newdata.imageURL && (
                <img
                  className="mx-auto d-block"
                  src={newdata.imageURL}
                  style={{ width: 120, height: "auto", maxHeight: 120 }}
                  alt="slide"
                />
              )}
              <small className="center-align d-block m-t-10">
                Max 600 X 600 px
              </small>
              <small className="center-align d-block">
                Large image takes time to load, so keep it optimal
              </small>
            </div>
            <div className="file-field input-field">
              <div className="btn blue darken-1">
                <span>Select Image</span>
                <input
                  type="file"
                  onChange={this.updateNewValues.bind(this, "imageURL")}
                />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate" type="text" />
              </div>
            </div>
            <div className="input-field m-b-10">
              <input
                value={newdata.ordering}
                id="newordering"
                type="number"
                className="validate"
                onChange={this.updateNewValues.bind(this, "ordering")}
              />
              <label
                className={newdata.ordering >= 0 ? "active" : ""}
                htmlFor="newordering"
              >
                Ordering
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={newdata.title || ""}
                id="newtitle"
                type="text"
                className="validate"
                onChange={this.updateNewValues.bind(this, "title")}
              />
              <label
                className={newdata.title ? "active" : ""}
                htmlFor="newtitle"
              >
                Title
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={newdata.tagline || ""}
                id="newtagline"
                type="text"
                className="validate"
                onChange={this.updateNewValues.bind(this, "tagline")}
              />
              <label
                className={newdata.tagline ? "active" : ""}
                htmlFor="newtagline"
              >
                Tag Line
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <Select
                isMulti={true}
                onChange={this.updateNewValues.bind(this, "membershipType")}
                options={this._options}
                isSearchable={false}
                placeholder={"Select Package"}
              />
            </div>
          </div>
        </div>
        <div className="text-right m-t-20">
          <button className="modal-action modal-close waves-effect waves-green btn-flat">
            Cancel
          </button>
          <button
            className="modal-close btn waves-effect waves-light btn-round blue"
            onClick={this.saveNew}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  render() {
    let { slideshow, loading, currentKey } = this.state;
    if (loading) {
      return null;
    }
    let { slides = [] } = slideshow;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s10">
            <h4>Package Features Slides</h4>
            <p className="small">
              This is the slideshow for the packages features
            </p>
          </div>
          <div className="col s2 text-right">
            <button
              className="btn btn-primary d-block mx-auto modal-trigger"
              data-target="newmodal"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="row">
          {slides &&
            slides.map((h, i) => {
              return this.renderSingle(h, i);
            })}
        </div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>
              You are sure to delete this slide, you won't be able to undo it.
            </p>
            <div className="text-right m-t-20">
              <button className="modal-action modal-close waves-effect waves-green btn-flat">
                Cancel
              </button>
              <button
                className="modal-close btn waves-effect waves-light btn-round blue"
                onClick={this.delete.bind(this, currentKey)}
              >
                Yes, I'm Sure
              </button>
            </div>
          </div>
        </div>
        <div id="editmodal" className="modal">
          {this.renderEditModal()}
        </div>
        <div id="newmodal" className="modal">
          {this.renderNewModal()}
        </div>
      </div>
    );
  }
}
