import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import InputEmoji from "../../components/InputEmoji";
import Select from "react-select";
import { NOTIFICATION_TYPE } from "../../lib/Constants";

class SendNotification extends ParentComponent {
  constructor(props) {
    super(props);
    const { uid } = props.match.params;
    this.state = {
      username: "",
      message: "",
      title: "",
      loading: false,
      imageURL: "",
      plainImageURL: "",
      imageFile: null,
      backgroundColor: null,
      icon: null,
      iconBackground: null,
      langCode: "en",
    };
    this.ref = firebase.firestore().collection("users").doc(uid);
  }

  notificationType = [
    { value: NOTIFICATION_TYPE.account, label: "Account" },
    { value: NOTIFICATION_TYPE.announcement, label: "Annoucement" },
    { value: NOTIFICATION_TYPE.misc, label: "Misc" },
    { value: NOTIFICATION_TYPE.system, label: "System" },
  ];

  LANGUAGE_OPTIONS = [
    { value: "en", label: "English" },
    { value: "zh-Hans", label: "Chinese (Simplified)" },
    { value: "zh-Hant", label: "Chinese (Traditional)" },
  ];

  showFilePicker = () => {
    this.fileEle?.click();
  };

  handleChange = (key, e) => {
    if (key === "imageURL") {
      let objURL = URL.createObjectURL(e.target.files[0]);
      this.updateState({ imageURL: objURL });
      this.updateState({
        imageFile: e.target.files && e.target.files[0] ? e.target.files[0] : "",
      });
    } else if (key === "plainImageURL") {
      this.updateState({ imageURL: e?.target?.value });
    } else {
      this.updateState({ [key]: e?.target?.value });
    }
  };

  componentWillUnmount() {
    if (this.unsubcribe) {
      this.unsubcribe();
    }
  }

  onLanguageChange = ({ value }) => {
    this.updateState({ langCode: value });
  };

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  uploadFileAndGetDownloadURL = async (imageURL, id) => {
    await firebase.storage().ref("notifications").child(id).put(imageURL);
    let downloadURL = await firebase
      .storage()
      .ref("notifications")
      .child(id)
      .getDownloadURL();
    return downloadURL;
  };

  appendEmoji = (e, key) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    this.updateState({ [key]: this.state?.[key] + emoji });
  };

  validate = () => {
    let valid = true;
    let { title, message } = this.state;
    if (!title || !message) {
      valid = false;
    }
    return valid;
  };

  sendNotification = async () => {
    const { uid } = this.props.match.params;
    const adminRef = firebase
      .firestore()
      .collection("admin")
      .doc("notifications")
      .collection("sent");
    const notificationRef = firebase
      .firestore()
      .collection("notifications")
      .doc(uid)
      .collection("notifications")
      .doc();
    let validateData = this.validate();
    const notificationID = notificationRef.id;
    if (!validateData) {
      this.showError("Please fill title or message");
      return;
    }
    this.updateState({ loading: true });
    this.showLoader();
    let {
      title,
      message,
      imageFile,
      backgroundColor,
      icon,
      iconBackground,
      langCode,
    } = this.state;
    let image;
    if (imageFile) {
      image = await this.uploadFileAndGetDownloadURL(imageFile, notificationID);
    }
    let users = [uid];
    const data = {
      title,
      users,
      message,
      langCode,
      imageURL: image ?? "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      read: false,
      resend: true,
      resendedTimes: 0,
      lastResent: "N/A",
      key: notificationID,
      notificationID: [notificationID],
      backgroundColor,
      icon,
      iconBackground,
    };
    let adminData = {
      ...data,
      resent: 0,
      userRef: firebase.firestore().collection("users").doc(uid),
    };
    await notificationRef.set(data, { merge: true });
    await adminRef.doc(notificationID).set(adminData, { merge: true });
    this.hideLoader();
    this.showSuccess("Notification sent successfully to server");
    this.updateState({ loading: false });
  };

  render() {
    const { uid } = this.props.match.params;
    return (
      <div className="container-fluid">
        <div className="col s12">
          <h4>Send Notifcation</h4>
        </div>
        <div className="card">
          <div className="card-content">
            <div className="m-t-20">
              <span>Send Notification</span>
            </div>
            <span>User &gt;&gt; {`${uid}`}</span>
            <div className="input-field col s12">
              <Select
                placeholder={"Language"}
                id={"langCode"}
                onChange={this.onLanguageChange}
                options={this.LANGUAGE_OPTIONS}
                isSearchable={false}
              />
            </div>
            <div className="m-t-20">
              <label>Notification Title</label>
              <div className="row">
                <div className="col s6">
                  <InputEmoji
                    value={this.state.title}
                    onChange={this.handleChange.bind(this, "title")}
                    onEmojiSelect={(e) => this.appendEmoji(e, "title")}
                    placeholder={"Title/Heading"}
                  />
                </div>
                <div className="col s6">
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className="d-flex flex-column upload-thumbnail jcac border-gray p-10 pointer"
                      onClick={this.showFilePicker}
                    >
                      {!this.state.imageURL && (
                        <>
                          <i className="fa fa-cloud-upload-alt fa-2x"></i>
                        </>
                      )}
                      {this.state.imageURL && (
                        <img
                          width="70"
                          height="70"
                          src={this.state.imageURL}
                          alt="Option"
                        />
                      )}
                    </div>
                    <input
                      value={this.state.imageURL}
                      placeholder="Image URL"
                      type="text"
                      className="validate h2rem"
                      onChange={this.handleChange.bind(this, "plainImageURL")}
                    />
                    <input
                      ref={(input) => (this.fileEle = input)}
                      type="file"
                      className="d-none"
                      onChange={this.handleChange.bind(this, "imageURL")}
                    />
                  </div>
                </div>
              </div>
              <div className="m-t-20">
                <label>Notification Message</label>
                <InputEmoji
                  value={this.state.message}
                  onChange={this.handleChange.bind(this, "message")}
                  onEmojiSelect={(e) => this.appendEmoji(e, "message")}
                  type="textarea"
                />
              </div>
            </div>
            <div className="m-t-10">
              <label className="relative">
                Background Color &nbsp;&nbsp;
                <input
                  className="basic"
                  type="text"
                  id={"backgroundColor"}
                  value={this.state.backgroundColor}
                  onChange={this.handleChange.bind(this, "backgroundColor")}
                />
                <input
                  type="color"
                  id={`backgroundColor`}
                  value={this.state.backgroundColor}
                  onChange={this.handleChange.bind(this, "backgroundColor")}
                />
              </label>
            </div>
            <div className={"d-flex flex-row align-items-center"}>
              <i className="material-icons m-r-10">info</i>
              <small className={"small"}>
                background color for popup message in the app, if leave blank
                the default system bacground color will be used
              </small>
            </div>
            <div className={"d-flex flex-row align-items-center m-t-20"}>
              <div className="m-r-20">
                <label className="relative">
                  Icon &nbsp;&nbsp;
                  <input
                    className="basic"
                    type="text"
                    id={"icon"}
                    value={this.state.icon}
                    onChange={this.handleChange.bind(this, "icon")}
                  />
                </label>
              </div>
              <div className="m-l-20">
                <label className="relative">
                  Icon Background &nbsp;&nbsp;
                  <input
                    className="basic"
                    type="text"
                    id={"iconBackground"}
                    value={this.state.iconBackground}
                    onChange={this.handleChange.bind(this, "iconBackground")}
                  />
                  <input
                    type="color"
                    id={`iconBackground`}
                    value={this.state.iconBackground}
                    onChange={this.handleChange.bind(this, "iconBackground")}
                  />
                </label>
              </div>
            </div>
            <div className={"d-flex flex-row align-items-center"}>
              <i className="material-icons m-r-10">info</i>
              <small className={"small"}>
                Please note that the icon must be from{" "}
                <a href="https://ionic.io/ionicons">ionicons</a> if supplied
                invalid icons, then the system default icon would be used
                instead.
                <br />
                Also note that the Icon background color is only used in the
                System Notification screen, if a background color is not
                supplied the system default color i.e.: #2ED3FF would be used
                instead.
              </small>
            </div>
            <div className="row m-t-20">
              <div className="col s12">
                <button
                  className="btn-large w100 blue"
                  onClick={this.sendNotification}
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SendNotification;
