/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../lib/AuthContext";

export default class MainMenu extends Component {
  static contextType = AuthContext;

  constructor(context) {
    super(context);
    this.state = {
      user: context.user,
    };
  }

  componentDidMount() {
    let $ = window.$;
    $(
      '#main-wrapper[data-layout="vertical"] #slide-out, #right-slide-out, .message-center, .scrollable, .pre-scroll'
    ).perfectScrollbar();
    $(".sidenav").sidenav();
    $(".dropdown-trigger").dropdown({
      alignment: "left",
      coverTrigger: false,
      hover: false,
      closeOnClick: false,
    });
    $(".collapsible").collapsible();
    $("body").trigger("resize");
    $(function () {
      var url = window.location;
      var element = $("ul.collapsible a")
        .filter(function () {
          return this.href === url;
        })
        .addClass("active")
        .parent()
        .addClass("active");
      while (true) {
        if (element.is("li")) {
          element = element
            .parent()
            .parent()
            .css({
              display: "block",
            })
            .parent()
            .addClass("active");
        } else {
          break;
        }
      }
    });
    $(".nav-toggle").on("click", function () {
      $("#main-wrapper").toggleClass("mini-sidebar");
      if ($("#main-wrapper").hasClass("mini-sidebar")) {
        $(".nav-toggle").prop("checked", !0);
        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
      } else {
        $(".nav-toggle").prop("checked", !1);
        $("#main-wrapper").attr("data-sidebartype", "full");
      }
    });
  }

  componentWillUnmount() {}

  render() {
    return (
      <aside className="left-sidebar">
        <ul id="slide-out" className="sidenav" data-sidebarbg="skin5">
          <li>
            <ul className="collapsible">
              <li className="small-cap">
                <span className="hide-menu">Manage Users</span>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">peoples</i>
                  <span className="hide-menu"> Users</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/users/all">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Show All</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">delete_forever</i>
                  <span className="hide-menu"> Delete Requests</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/users/delete-requests">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Show All</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="small-cap">
                <span className="hide-menu">Reach</span>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">notifications</i>
                  <span className="hide-menu"> Notifications</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <a className="collapsible-header">
                        <i className="material-icons">assistant</i>
                        <span className="hide-menu"> Sent</span>
                      </a>
                      <div className="">
                        <ul>
                          <li>
                            <NavLink to="/notifcation/sentToUsers/">
                              <i className="material-icons">send</i>
                              <span className="hide-menu">Sent to users</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/notifcation/sentToGroup">
                              <i className="material-icons">group</i>
                              <span className="hide-menu">Sent to Group</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <hr className={"seperator"} />
                    <li>
                      <NavLink to="/notifcation/all-users/">
                        <i className="material-icons">send</i>
                        <span className="hide-menu">Send</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/notifcation/group-notification">
                        <i className="material-icons">group</i>
                        <span className="hide-menu">Send to Group</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="small-cap">
                <span className="hide-menu">Engage</span>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">group</i>
                  <span className="hide-menu"> Communities/Groups</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/ads/tools/types">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Create</span>
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/ads/tools/industries">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Show All</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">calendar-clock</i>
                  <span className="hide-menu"> Events</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/ads/tools/types">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Create New</span>
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/ads/tools/industries">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Show All</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="small-cap">
                <span className="hide-menu">Payments</span>
              </li>
              <li>
                <NavLink to="/packageslides">
                  <i className="material-icons small">perm_media</i>
                  <span className="hide-menu"> Package Slides</span>
                </NavLink>
              </li>
              <li className="small-cap">
                <span className="hide-menu">Tools</span>
              </li>
              <li>
                <NavLink to="/settings/config">
                  <i className="material-icons small">settings</i>
                  <span className="hide-menu"> App Config</span>
                </NavLink>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">assistant</i>
                  <span className="hide-menu"> Map Ads Tools</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/ads/tools/types">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Types</span>
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/ads/tools/industries">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Industries</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <NavLink to="/allads">
                  <i className="material-icons small">map_marker</i>
                  <span className="hide-menu"> Map Ads</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/welcomesliders">
                  <i className="material-icons small">perm_media</i>
                  <span className="hide-menu"> Welcome Sliders</span>
                </NavLink>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">flag</i>
                  <span className="hide-menu"> Report Problem</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/reports/all">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Show All</span>
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/report-problem/reported">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Reported Problems</span>
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <NavLink to="/report-problem/feedbacks">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Feedbacks</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="collapsible-header has-arrow">
                  <i className="material-icons">import_contacts</i>
                  <span className="hide-menu"> Pages</span>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <NavLink to="/pages/terms">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Terms</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/pages/privacy">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">Privacy</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/pages/faq">
                        <i className="material-icons">adjust</i>
                        <span className="hide-menu">FAQ</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    );
  }
}
