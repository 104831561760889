import React, { Component } from 'react'
import Header from '../../components/Header';
import MainMenu from '../../components/MainMenu';
import { AuthContext } from '../../lib/AuthContext';

export default class Layout extends Component {

  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      user: context.user,
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="overlay-loader" id="myc-loader">
          <div className="icc-holder">
            <i className="fa fa-spinner fa-spin fa-2x" />
          </div>
        </div>
        <div className="main-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
          <Header />
          <MainMenu />
          <div className="page-wrapper">
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
};