import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import dayjs from "dayjs";
import Utils from "../../lib/Utils";
import { CSVLink } from "react-csv";
import { ColorRing } from "react-loader-spinner";

class NotificationsShowAll extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      loading: true,
      notificationUser: [],
      csvData: [],
      usersLoading: true,
    };
    this.notificationRef = firebase
      .firestore()
      .collection("notifications")
      .doc("group-notifications")
      .collection("notifications");
    this.usersRef = firebase.firestore().collection("users");
  }

  componentDidMount() {
    let $ = window.$;
    this.unsubcribeNotification = this.notificationRef
      .orderBy("createdAt")
      .onSnapshot((snap) => {
        let notifications = [];
        const { convertToCSV } = Utils;
        if (!snap.empty) {
          snap.docs.forEach((data) => {
            if (data.id !== "total") {
              notifications.push(data.data());
            }
          });
        }
        const csvData = convertToCSV(notifications);
        this.updateState({ notifications, csvData, loading: false });
        $(".tooltipped").tooltip({ outDuration: 0 });
      });
    $(".modal").modal();
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    if (this.unsubcribeNotification) {
      this.unsubcribeNotification();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    Notifications sent from server (
                    {dataArray ? dataArray.length : 0})
                  </h5>
                  <small>Notifications sent to the group of users</small>
                </div>
                <div className={"col s4 text-right"}>
                  {!!this.state.csvData && (
                    <CSVLink
                      ref={this.csvLink}
                      data={this.state.csvData}
                      enclosingCharacter={`"`}
                      filename={`sentToUsers_data_${new Date().getMilliseconds()}.csv`}
                      className="btn waves-effect waves-light btn-round blue modal-trigger"
                    >
                      Export Data
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Users</th>
                  <th>Resended</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#FF2E63",
                      "#2ED3FF",
                      "#5FC88F",
                      "#DF9F28",
                      "#767DFF",
                    ]}
                  />
                )}
                {!loading && dataArray.length === 0 && (
                  <tr>
                    <td colSpan="6">Currently No notification is sent</td>
                  </tr>
                )}
                {dataArray.map((val, i) => {
                  return (
                    <tr key={`${val.key}`} className={""}>
                      <td>
                        <span>{val.title} &nbsp;&nbsp;</span>
                        {val.published === false && (
                          <i
                            className="tooltipped fas fa-eye-slash"
                            data-position="top"
                            data-tooltip="Unpublished"
                          />
                        )}
                      </td>
                      <td>{`${val.message.substring(0, 50)}...`}</td>
                      <td>
                        {val?.imageURL ? (
                          <img
                            src={val?.imageURL}
                            style={{ width: "auto", height: 40 }}
                            alt="parent"
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </td>
                      <td>{new Date(val?.createdAt.toDate()).toUTCString()}</td>
                      <td>
                        <span
                          data-position="top"
                          data-tooltip="Users"
                          className="tooltipped pointer modal-trigger"
                          data-target="usersModal"
                          onClick={this.showUsers.bind(this, val)}
                        >
                          Click to see
                        </span>
                      </td>
                      <td>{`${val?.resendedTimes ?? 0} times`}</td>
                      <td>
                        <i
                          data-position="top"
                          data-tooltip="Delete"
                          className="tooltipped material-icons p-5 pointer modal-trigger"
                          data-target="deletionconfirm"
                          onClick={this.setCurrent.bind(this, val)}
                        >
                          delete
                        </i>
                        <i
                          data-position="top"
                          data-tooltip="Resend"
                          className="tooltipped material-icons p-5 pointer modal-trigger"
                          data-target="resendconfirm"
                          onClick={this.setCurrent.bind(this, val)}
                        >
                          refresh
                        </i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  renderUsersModal = () => {
    let { usersLoading, notificationUser } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    Users Assigned to this Notification (
                    {notificationUser ? notificationUser.length : 0})
                  </h5>
                </div>
              </div>
            </div>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>uid</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Last Seen</th>
                  <th>Created At</th>
                  <th>Is Pro</th>
                </tr>
              </thead>
              <tbody>
                {usersLoading && (
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#FF2E63",
                      "#2ED3FF",
                      "#5FC88F",
                      "#DF9F28",
                      "#767DFF",
                    ]}
                  />
                )}
                {!usersLoading && notificationUser.length === 0 && (
                  <tr>
                    <td colSpan="6">
                      No Users were assigned to this notification
                    </td>
                  </tr>
                )}
                {notificationUser.map((val, i) => {
                  return (
                    <tr key={`${val.uid}`} className={""}>
                      {val?.uid}
                      <td>{val?.email}</td>
                      <td>{val?.phoneNumber}</td>
                      <td>
                        {dayjs(val?.last_seen?.toDate()).format("DD MM YYYY")}
                      </td>
                      <td>
                        {dayjs(val?.createdAt?.toDate()).format("DD MM YYYY")}
                      </td>
                      <td>{val?.isPro ? "Yes" : "No"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  setCurrent = (item) => {
    this.setState({ current: item });
  };

  showUsers = async (selectedNotification) => {
    if (!selectedNotification) {
      return;
    }
    let { users } = selectedNotification;
    let notificationUser = [];
    this.updateState({ usersLoading: true });
    for await (const user of users) {
      let dbRef = await this.usersRef.doc(user).get();
      if (!dbRef.exists) {
        continue;
      }
      notificationUser.push(dbRef?.data());
    }
    this.updateState({ notificationUser, usersLoading: false });
  };

  deleteNotification = () => {
    let { current } = this.state;
    if (current) {
      let { key } = current;
      this.notificationRef.doc(key).delete();
    }
  };

  resendNotification = () => {
    let { current } = this.state;
    if (current) {
      let { key } = current;
      this.notificationRef.doc(key).update({ resend: true }, { merge: true });
    }
  };

  render() {
    let { notifications } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">{this.getTableData(notifications)}</div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>
              You sure to delete this language, you won't be able to undo it.
            </p>
          </div>
          <div className="modal-footer">
            <button className="modal-action modal-close waves-effect waves-green btn-flat">
              Cancel
            </button>
            <button
              className="modal-close btn waves-effect waves-light btn-round blue"
              onClick={this.deleteNotification}
            >
              Yes, I'm Sure
            </button>
          </div>
        </div>
        <div id="resendconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure to resend ?</h4>
            <p>
              Are you sure to resend this notification to the users assigned?
            </p>
          </div>
          <div className="modal-footer">
            <button className="modal-action modal-close waves-effect waves-green btn-flat">
              Cancel
            </button>
            <button
              className="modal-close btn waves-effect waves-light btn-round blue"
              onClick={this.resendNotification}
            >
              Resend
            </button>
          </div>
        </div>
        <div id="usersModal" className="modal">
          <div className="modal-content">{this.renderUsersModal()}</div>
        </div>
      </div>
    );
  }
}

export default NotificationsShowAll;
