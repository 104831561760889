import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";

const config = {
  apiKey: "AIzaSyBdo0ij3tigE57e4TaormcQAcehZqZ0-9E",
  authDomain: "dimjack-a954d.firebaseapp.com",
  databaseURL:
    "https://dimjack-a954d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dimjack-a954d",
  storageBucket: "dimjack-a954d.appspot.com",
  messagingSenderId: "713808823937",
  appId: "1:713808823937:web:84fcfc386806c578aaef80",
  measurementId: "G-HNF82341KZ",
};

firebase.initializeApp(config);
export const auth = firebase.auth();
export default firebase;
