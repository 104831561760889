import React, { Component } from "react";
import { AuthContext } from "../../lib/AuthContext";
import { ADMIN_URL } from "../../lib/Constants";
import Utils from "../../lib/Utils";
import firebase from "../../lib/Firebase";

export default class Home extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);
    this.state = {
      user: context.user,
      usersCount: 0,
      onlineUsers: 0,
    };
    this.mounted = true;
  }

  async componentDidMount() {
    if (this.mounted) {
      const data = await Utils.request(`${ADMIN_URL}/GetUsers`, "GET");
      const getOnlineUsers = await (
        await firebase
          .database()
          .ref("users")
          .orderByChild("isOnline")
          .equalTo(true)
          .get()
      ).toJSON();
      const onlineUsers = Object.keys(getOnlineUsers).length;
      this.updateState({ usersCount: data?.length, onlineUsers });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  render() {
    let { usersCount, onlineUsers } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col l3 m6 s12">
            <div className="card info-gradient card-hover">
              <div className="card-content">
                <div className="d-flex no-block align-items-center">
                  <div>
                    <h2 className="white-text m-b-5">{usersCount}</h2>
                    <h6 className="white-text op-5">Users</h6>
                  </div>
                  <div className="ml-auto">
                    <span className="white-text display-6">
                      <i className="fas fa-users fa-2xl icon-bg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card info-gradient card-hover">
              <div className="card-content">
                <div className="d-flex no-block align-items-center">
                  <div>
                    <h2 className="white-text m-b-5">{onlineUsers}</h2>
                    <h6 className="white-text op-5">Online Users</h6>
                  </div>
                  <div className="ml-auto">
                    <span className="white-text display-6">
                      <i className="fas fa-users fa-2xl icon-bg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
