import React from 'react';
export default class ParentComponent extends React.Component {
  constructor(props,context) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() { }

  showError = (message, title='') => {
    window.toastr.error(message, title, { positionClass: 'toast-container toast-top-center'});
  };

  showSuccess = (message, title ='') => {
    window.toastr.success(message, title, { positionClass: 'toast-container toast-top-center'});
  };

  showLoader = () => {
    window.$('#myc-loader').css('display', 'flex');
  };

  hideLoader = () => {
    window.$('#myc-loader').css('display', 'none');
  };
}