import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";

class Reports extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      loading: true,
    };
    this.ref = firebase.firestore().collection("reports");
  }

  componentDidMount() {
    let $ = window.$;
    this.unsub = this.ref.orderBy("createdAt").onSnapshot((snap) => {
      let reports = [];
      if (!snap.empty) {
        snap.docs.forEach((data) => {
          if (data.id !== "total") {
            reports.push(data.data());
          }
        });
      }
      this.updateState({ reports, loading: false });
      $(".tooltipped").tooltip({ outDuration: 0 });
    });
    $(".modal").modal();
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    if (this.unsub) {
      this.unsub();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    Reports by Users ({dataArray ? dataArray.length : 0})
                  </h5>
                </div>
              </div>
            </div>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Reason</th>
                  <th>Remarks</th>
                  <th>Reporter</th>
                  <th>Reported User</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading && dataArray.length === 0 && (
                  <tr>
                    <td colSpan="6">Currently No Reports are submitted</td>
                  </tr>
                )}
                {dataArray.map((val, i) => {
                  return (
                    <tr key={`${val.reportId}`} className={""}>
                      <td>
                        <span>{val?.reason}</span>
                      </td>
                      <td>{val.remarks ?? "No Remarks"}</td>
                      <td>{val?.uid}</td>
                      <td>{val?.reportedUid}</td>
                      <td>{new Date(val?.createdAt.toDate()).toUTCString()}</td>
                      <td>
                        <i
                          data-position="top"
                          data-tooltip="Decline Report"
                          className="tooltipped small material-icons materialize-red-text text-darken-1 p-5 pointer modal-trigger"
                          data-target="deletionconfirm"
                          onClick={this.setCurrent.bind(this, val)}
                        >
                          clear
                        </i>
                        <i
                          data-position="top"
                          data-tooltip="Approve"
                          className="tooltipped small material-icons p-5 blueColor pointer modal-trigger"
                          data-target="resendconfirm"
                          onClick={this.setCurrent.bind(this, val)}
                        >
                          done
                        </i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  setCurrent = (item) => {
    this.setState({ current: item });
  };

  showUsers = async (selectedNotification) => {
    console.log("triggered");
    if (!selectedNotification) {
      return;
    }
    let { users } = selectedNotification;
    let notificationUser = [];
    this.updateState({ loading: true });
    for await (const user of users) {
      let dbRef = await this.usersRef.doc(user).get();
      if (dbRef.exists) {
        notificationUser.push(dbRef?.data());
      }
    }
    this.updateState({ notificationUser, loading: false });
  };

  deleteNotification = () => {
    let { current } = this.state;
    if (current) {
      let { key } = current;
      this.notificationRef.doc(key).delete();
    }
  };

  resendNotification = () => {
    let { current } = this.state;
    if (current) {
      let { key } = current;
      this.notificationRef
        .doc(key)
        .update(
          { resend: firebase.firestore.FieldValue.increment(1) },
          { merge: true }
        );
    }
  };

  render() {
    let { reports } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">{this.getTableData(reports)}</div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>
              You sure to delete this language, you won't be able to undo it.
            </p>
          </div>
          <div className="modal-footer">
            <button className="modal-action modal-close waves-effect waves-green btn-flat">
              Cancel
            </button>
            <button
              className="modal-close btn waves-effect waves-light btn-round blue"
              onClick={this.deleteNotification}
            >
              Yes, I'm Sure
            </button>
          </div>
        </div>
        <div id="resendconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure to resend ?</h4>
            <p>
              Are you sure to resend this notification to the users assigned?
            </p>
          </div>
          <div className="modal-footer">
            <button className="modal-action modal-close waves-effect waves-green btn-flat">
              Cancel
            </button>
            <button
              className="modal-close btn waves-effect waves-light btn-round blue"
              onClick={this.resendNotification}
            >
              Resend
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Reports;
