import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { ADMIN_URL } from "../../lib/Constants";
import Utils from "../../lib/Utils";
import {
  FacebookRounded,
  Apple,
  Smartphone,
  MailOutline,
  ContentCopy,
  ModeEditOutlined,
  DeleteOutline,
  SendOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import firebase from "../../lib/Firebase";

const ShowAllUsers = (props) => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [dbError, setDBError] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      try {
        const data = await Utils.request(`${ADMIN_URL}/GetUsers`, "GET");
        setAllUsers(data);
        let start = pagination.pageIndex * pagination.pageSize;
        const newArr = data?.slice(
          parseInt(start),
          parseInt(start) + parseInt(pagination.pageSize)
        );

        const dataToCSV = Utils.convertToCSV(data);
        setCSVData(dataToCSV);
        setData(newArr);
        setRowCount(data?.length);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!allUsers.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      try {
        let start = pagination.pageIndex * pagination.pageSize;
        const newArr = allUsers.slice(
          parseInt(start),
          parseInt(start) + parseInt(pagination.pageSize)
        );
        setData(newArr);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [allUsers, pagination.pageIndex, pagination.pageSize]);

  function getIcon(provider) {
    switch (provider) {
      case "apple.com":
        return <Apple />;
      case "facebook.com":
        return <FacebookRounded />;
      case "phone":
        return <Smartphone />;
      default:
        return <MailOutline />;
    }
  }

  function getCredentials(provider) {
    const providerData = provider[0];
    const providerId = providerData?.providerId;
    switch (providerId) {
      case "phone":
        return providerData?.phoneNumber;
      default:
        return providerData?.email;
    }
  }

  const sourceOptions = [
    {
      text: "Facebook",
      value: "facebook.com",
    },
    {
      text: "Apple",
      value: "apple.com",
    },
    {
      text: "Email",
      value: "password",
    },
    {
      text: "Phone",
      value: "phone",
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "uid",
        header: "UID",
        enableColumnFilterModes: false,
        columnFilterModeOptions: ["fuzzy", "contains", "startsWith"],
        enableSorting: false,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: "flex-start" },
        },
      },
      //column definitions...
      {
        accessorFn: (row) => row.providerData[0]?.providerId,
        header: "Source",
        id: "providerId",
        Cell: ({ row }) => {
          return getIcon(row?.original?.providerData[0]?.providerId);
        },
        enableSorting: false,
        filterVariant: "multi-select",
        filterSelectOptions: sourceOptions,
      },
      {
        id: "credential",
        accessorFn: (row) => getCredentials(row.providerData),
        header: "Credential",
        Cell: ({ row }) => {
          return getCredentials(row?.original?.providerData);
        },
        enableSorting: false,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: "flex-start" },
        },
        columnFilterModeOptions: ["fuzzy", "contains", "startsWith"],
      },
      {
        accessorKey: "creationTime",
        header: "Created At",
        accessorFn: (row) => row?.original?.metadata?.creationTime,
        Cell: ({ row }) => row?.original?.metadata?.creationTime,
        enableColumnFilter: false,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastSignInTime",
        header: "Last Login",
        enableColumnFilter: false,
        accessorFn: (row) => row?.original?.metadata?.creationTime,
        Cell: ({ row }) => row?.original?.metadata?.lastSignInTime,
        sortingFn: "datetime",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function _editUserHandler(uid) {
    props.history.push(`/users/${uid}`);
  }

  function _sendNotification(uid) {
    props.history.push(`/notifcation/send/${uid}`);
  }

  const deleteUser = async () => {
    if (selectedUser) {
      return setDeleteAlert(false);
    }
    setDeleting(true);
    const { uid } = selectedUser;
    try {
      await Utils.request(`${ADMIN_URL}/DeleteUser`, "POST", {
        uid,
      });
      await firebase.firestore().collection("users").doc(uid).delete();
    } catch (error) {
      setDBError(true);
      setErrMessage(error?.message);
    }
    setDeleting(false);
    return _closeModal();
  };

  function _showDeleteAlert(user) {
    console.log("User -> ", user);
    setSelectedUser(user);
    return setDeleteAlert(true);
  }

  function _closeModal() {
    setDeleteAlert(false);
    return setSelectedUser(null);
  }

  function _closeError() {
    setDBError(false);
    return _closeModal();
  }

  function _rowAction({ row }) {
    return [
      <MenuItem
        key={row?.id}
        sx={{ m: 0 }}
        onClick={_editUserHandler.bind(this, row?.id)}
      >
        <ListItemIcon>
          <ModeEditOutlined />
        </ListItemIcon>
        Edit
      </MenuItem>,
      <MenuItem
        key={`delete_${row?.id}`}
        onClick={_showDeleteAlert.bind(this, row?.original)}
      >
        <ListItemIcon>
          <DeleteOutline />
        </ListItemIcon>
        Delete
      </MenuItem>,
      <MenuItem
        key={`notification_${row?.id}`}
        onClick={_sendNotification.bind(this, row?.id)}
      >
        <ListItemIcon>
          <SendOutlined />
        </ListItemIcon>
        Send Notification
      </MenuItem>,
    ];
  }

  function _renderTopAction() {
    return (
      <Box sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}>
        <CSVLink
          data={csvData}
          enclosingCharacter={`"`}
          filename={`allUsers_data_${new Date().getMilliseconds()}.csv`}
          className="btn waves-effect waves-light btn-round blue modal-trigger"
        >
          <FileDownloadIcon />
          Export Data
        </CSVLink>
      </Box>
    );
  }

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data}
        getRowId={(row) => row.uid}
        initialState={{ showColumnFilters: true }}
        manualPagination
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
        }}
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={_renderTopAction}
        positionActionsColumn="last"
        enableRowActions
        renderRowActionMenuItems={_rowAction}
      />
      <Dialog
        open={deleteAlert}
        onClose={_closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedUser &&
              `Are you sure you want to delete ${
                selectedUser?.id
              } with credentials ${getCredentials(
                selectedUser?.providerData
              )} Remember that once the user is deleted, the data will not be reversed`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"}>Cancel</Button>
          <Button
            variant="contained"
            color={"error"}
            onClick={deleteUser}
            startIcon={<DeleteOutline />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={deleting}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={dbError}
        autoHideDuration={6000}
        onClose={_closeError}
      >
        <Alert
          severity="error"
          sx={{ width: "120%" }}
          variant="filled"
          elevation={6}
        >
          {errMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ShowAllUsers;
