import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import axios from "axios";
import {
  ADMIN_URL,
  DELETE_REQUEST_STATUS,
  DELETE_REQ_STATUS_LABEL,
} from "../../lib/Constants";
import Select from "react-select";
import dayjs from "dayjs";
import Utils from "../../lib/Utils";
import { CSVLink } from "react-csv";
import { ColorRing } from "react-loader-spinner";

const customSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    height: "40px",
    width: "200px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    top: "-10px",
  }),
};

class AllUsers extends ParentComponent {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      requests: [],
      loading: true,
      csvData: [],
    };
    this.requestsRef = firebase.firestore().collection("deleteRequests");
  }

  async componentDidMount() {
    let $ = window.$;
    this.unsubscribe = this.requestsRef
      .orderBy("createdAt", "desc")
      .onSnapshot((snap) => {
        const { convertToCSV } = Utils;
        let requests = [];
        if (!snap.empty) {
          snap.docs.forEach((data) => {
            if (data.id !== "total") {
              requests.push(data.data());
            }
          });
        }
        const csvData = convertToCSV(requests);
        this.updateState({ requests, csvData, loading: false });
        $(".tooltipped").tooltip({ outDuration: 0 });
        $(".table").footable({
          paging: {
            enabled: true,
          },
        });
      });
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    this.mounted = false;
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  _options = [
    {
      value: DELETE_REQUEST_STATUS?.completed,
      label: DELETE_REQ_STATUS_LABEL?.completed,
    },
    {
      value: DELETE_REQUEST_STATUS?.cancelled,
      label: DELETE_REQ_STATUS_LABEL?.cancelled,
    },
    {
      value: DELETE_REQUEST_STATUS?.processing,
      label: DELETE_REQ_STATUS_LABEL?.processing,
    },
    {
      value: DELETE_REQUEST_STATUS?.on_hang,
      label: DELETE_REQ_STATUS_LABEL?.on_hang,
    },
  ];

  loadOther = (val, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    this.props.history.push(`/users/${val}`);
  };

  deleteUser = async () => {
    let { current } = this.state;
    if (current) {
      let { uid } = current;
      this.showLoader();
      let res = await axios.post(
        `${ADMIN_URL}/DeleteUser`,
        { uid },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let { error } = res.data;
      this.hideLoader();
      if (error) {
        this.showError(error);
        return;
      }
      await firebase.firestore().collection("users").doc(uid).delete();
      this.showSuccess("User deleted successfully");
      window.location.reload();
    }
  };

  filterSearch = (val) => {
    let { users } = this.state;
    this.updateState({ searchValue: val?.target?.value });
    let value = val?.target?.value?.toLowerCase();
    let filteredUsers = users.filter((user) =>
      user?.displayName?.toLowerCase().includes(value)
    );
    this.updateState({ filteredUsers });
  };

  onStatusChange = async ({ requestID }, { value }) => {
    if (!requestID) {
      return;
    }
    this.showLoader();
    try {
      await this.requestsRef.doc(requestID).update({
        status: value,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {}
    this.hideLoader();
  };

  setCurrent = (item) => {
    this.updateState({ current: item });
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    All Requests ({dataArray ? dataArray.length : 0})
                  </h5>
                </div>
                <div className={"col s4 text-right"}>
                  {!!this.state.csvData && (
                    <CSVLink
                      ref={this.csvLink}
                      data={this.state.csvData}
                      enclosingCharacter={`"`}
                      filename={`deleteRequests_${new Date().getMilliseconds()}`}
                      className="btn waves-effect waves-light btn-round blue modal-trigger"
                    >
                      Export Data
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table
                id="table"
                className="table m-t-10 highlight toggle-circle footable"
                data-filter="#filter"
                data-page-size="20"
              >
                <thead>
                  <tr>
                    <th data-type="numeric" data-sort-initial="true">
                      No.
                    </th>
                    <th
                      data-sort-ignore="true"
                      data-sorter="false"
                      className={"sorter-false"}
                    >
                      User
                    </th>
                    <th data-sorter="false" data-sort-ignore="true">
                      Source
                    </th>
                    <th data-sorter="false" data-sort-ignore="true">
                      Last Login
                    </th>
                    <th data-sorter="false" data-sort-ignore="true">
                      Account Created
                    </th>
                    <th
                      data-type="numeric"
                      className={"footable-sortable footable-desc"}
                    >
                      Request Time
                    </th>
                    <th data-sorter="false">Status</th>
                    <th data-type="boolean" className={"footable-sortable"}>
                      Deleted
                    </th>
                    <th data-sort-ignore="true" className="min-width">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <ColorRing
                      visible={true}
                      height="100"
                      width="100"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={[
                        "#FF2E63",
                        "#2ED3FF",
                        "#5FC88F",
                        "#DF9F28",
                        "#767DFF",
                      ]}
                    />
                  )}
                  {!loading && dataArray.length === 0 && (
                    <tr>
                      <td colSpan="6">
                        This section is currently empty
                        <br />
                        Consider adding new
                      </td>
                    </tr>
                  )}
                  {dataArray.map((val, i) => {
                    let { providerData, metadata } = val;
                    return (
                      <tr key={`${val.uid}`} className={"hovertr pointer"}>
                        <td>{i + 1}</td>
                        <td>
                          {providerData[0]?.providerId === "password"
                            ? providerData[0]?.email
                            : providerData[0]?.providerId === "phone"
                            ? providerData[0]?.phoneNumber
                            : providerData[0]?.providerId === "apple.com"
                            ? providerData[0]?.email
                            : providerData[0]?.email}
                        </td>
                        <td>
                          <i
                            className={
                              providerData[0]?.providerId === "password"
                                ? "fas fa-envelope fa-lg purpleColor"
                                : providerData[0]?.providerId === "phone"
                                ? "fas fa-mobile fa-lg blueColor"
                                : providerData[0]?.providerId === "google.com"
                                ? "fab fa-google fa-lg brand-google"
                                : "fab fa-facebook fa-lg brand-facebook"
                            }
                          />
                        </td>
                        <td>
                          {dayjs(metadata?.lastSignInTime).format(
                            "DD-MMM-YY hh:mm:s A Z"
                          )}
                        </td>
                        <td>
                          {dayjs(metadata?.creationTime).format("DD-MMM-YY")}
                        </td>
                        <td data-value={dayjs(metadata?.creationTime)?.unix()}>
                          {dayjs(val?.createdAt?.toDate()).format("DD-MMM-YY")}
                        </td>
                        <td>
                          {
                            <Select
                              isMulti={false}
                              onChange={this.onStatusChange.bind(this, val)}
                              options={this._options}
                              isSearchable={false}
                              placeholder={"Select Status"}
                              styles={customSelectStyle}
                            />
                          }
                        </td>
                        <td data-value={`${val?.disabled}`}>
                          {val?.isDeleted ? (
                            <span className="label label-table label-success">
                              Yes
                            </span>
                          ) : (
                            <span className="label label-table label-danger">
                              No
                            </span>
                          )}
                          <span>{val?.disabled}</span>
                        </td>
                        <td>
                          <i
                            data-position="top"
                            data-tooltip="Approve"
                            className="tooltipped p-5 pointer fas fa-check fa-lg indigo-text"
                            onClick={this.loadOther.bind(this, val?.uid)}
                          />
                          <i
                            data-position="top"
                            data-tooltip="Decline"
                            className="tooltipped fas fa-times fa-lg materialize-red-text pointer modal-trigger"
                            data-target="deletionconfirm"
                            onClick={this.setCurrent.bind(this, val)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7">
                      <div className="right-align">
                        <ul className="pagination">
                          {" "}
                          <li className="footable-page-arrow disabled">
                            <a data-page="first" href="#first">
                              «
                            </a>
                          </li>
                          <li className="footable-page-arrow disabled">
                            <a data-page="prev" href="#prev">
                              ‹
                            </a>
                          </li>
                          <li className="footable-page-arrow">
                            <a data-page="next" href="#next">
                              ›
                            </a>
                          </li>
                          <li className="footable-page-arrow">
                            <a data-page="last" href="#last">
                              »
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { requests } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">{this.getTableData(requests)}</div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>You sure to delete this user, you won't be able to undo it.</p>
          </div>
          <div className="modal-footer">
            <button className="modal-action modal-close waves-effect waves-green btn-flat">
              Cancel
            </button>
            <button
              className="modal-close btn waves-effect waves-light btn-round blue"
              onClick={this.deleteUser}
            >
              Yes, I'm Sure
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AllUsers;
