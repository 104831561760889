import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import dayjs from "dayjs";

class NotificationUsers extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      searchValue: null,
      filteredUsers: [],
    };
    this.userRef = firebase.firestore().collection("users");
  }

  async componentDidMount() {
    let $ = window.$;
    this.unsubcribeUser = this.userRef
      .where("notificationID", "!=", false)
      .onSnapshot((snap) => {
        let users = [];
        if (!snap.empty) {
          snap.docs.forEach((data) => {
            let user = { ...data.data(), uid: data.id };
            users.push(user);
          });
        }
        this.updateState({ users, loading: false });
        $(".tooltipped").tooltip({ outDuration: 0 });
      });
    $(".modal").modal();
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    if (this.unsubcribeUser) {
      this.unsubcribeUser();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  loadOther = (val, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    this.props.history.push(`/notifcation/send/${val}`);
  };

  filterSearch = (val) => {
    let { users } = this.state;
    this.updateState({ searchValue: val?.target?.value });
    let value = val?.target?.value?.toLowerCase();
    let filteredUsers = users.filter(
      (user) =>
        user?.uid?.toLowerCase().includes(value) ||
        user?.email?.toLowerCase().includes(value)
    );
    this.updateState({ filteredUsers });
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    All Users List ({dataArray ? dataArray.length : 0})
                  </h5>
                  <small>Click to send notification</small>
                </div>
                <div className="col s4 text-right">
                  <div className="col s6">
                    <input
                      type="text"
                      id="searchBar"
                      className={"materialize-textarea"}
                      placeholder="Search User"
                      onChange={(val) => this.filterSearch(val)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Created At</th>
                  <th>Last Login</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading && dataArray.length === 0 && (
                  <tr>
                    <td colSpan="6">
                      This section is currently empty
                      <br />
                      Consider adding new
                    </td>
                  </tr>
                )}
                {dataArray.map((val, _) => {
                  return (
                    <tr
                      key={`${val.uid}`}
                      className={"hovertr pointer"}
                      onClick={this.loadOther.bind(this, val?.uid)}
                    >
                      <td className="flex-row">
                        {val?.profileAvtar && (
                          <img
                            src={val?.profileAvtar}
                            className="userAvtar"
                            alt="user-profile"
                          />
                        )}
                        <span className="chip">{val.uid}</span>
                      </td>
                      <td>
                        <span
                          className={`text-cc ${
                            val?.gender === "male"
                              ? "secondary-text"
                              : val?.gender === "female"
                              ? "primary-text"
                              : "other-gender-text"
                          }`}
                        >
                          {val?.gender}
                        </span>
                      </td>
                      <td>
                        <span className="black-text2">
                          {val?.email ? val?.email : "Anonymous User"}
                        </span>
                      </td>
                      <td>
                        {dayjs(new Date(val?.createdAt?.toDate())).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td>
                        {dayjs(val?.last_seen?.toDate()).format("DD-MMM-YY")}
                      </td>
                      <td>
                        {!val?.disabled ? (
                          <span className="label label-success">Yes</span>
                        ) : (
                          <span className="label label-warning">No</span>
                        )}
                        <span>{val?.disabled}</span>
                      </td>
                      <td>
                        <i
                          data-position="top"
                          data-tooltip="Send Notification"
                          className="tooltipped material-icons p-5 pointer secondary-text"
                          onClick={this.loadOther.bind(this, val?.uid)}
                        >
                          send
                        </i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  setCurrent = (item) => {
    this.setState({ current: item });
  };

  render() {
    let { users, searchValue, filteredUsers } = this.state;
    return (
      <div className="container-fluid">
        {searchValue ? (
          <div className="row">{this.getTableData(filteredUsers)}</div>
        ) : (
          <div className="row">{this.getTableData(users)}</div>
        )}
      </div>
    );
  }
}

export default NotificationUsers;
