import React, { useState, useEffect } from "react";
import { AuthContext } from "./lib/AuthContext";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import firebase from "./lib/Firebase";
import Login from "./Screens/Login";
import Layout from "./Screens/Layout";
import Home from "./Screens/Home";
import Terms from "./Screens/Pages/terms";
import Privacy from "./Screens/Pages/privacy";
import NotificationUsers from "./Screens/Notifications/allUsers";
import SentToUsers from "./Screens/Notifications/sentToUser";
import SentToGroup from "./Screens/Notifications";
import SendNotification from "./Screens/Notifications/sendNotification";
import GroupNotification from "./Screens/Notifications/sendToGroup";
import AllUsers from "./Screens/Users";
import UserScreen from "./Screens/Users/UserScreen";
import WelcomeSlider from "./Screens/WelcomeSlider";
import DeleteRequests from "./Screens/Users/deleteRequests";
import PackagesSlides from "./Screens/Packageslides";
import FAQ from "./Screens/Pages/faq";
import Reports from "./Screens/ReportProblem";
import AllAds from "./Screens/MapAds";
import AdsAddUpdate from "./Screens/MapAds/addOrUpdate";
import AdIndustry from "./Screens/MapAds/industries";
import AdTypes from "./Screens/MapAds/businessTypes";
import Settings from "./Screens/Settings";

const PrivateRoute = ({ user, ...props }) => {
  if (user) {
    return (
      <Layout {...props}>
        <Route {...props} />
      </Layout>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

function App() {
  const [authDetails, setAuthDetails] = useState({
    loaded: false,
    user: null,
    voices: [],
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let userPrivateRef = firebase
          .firestore()
          .collection("users")
          .doc(user.uid);
        userPrivateRef.get().then(async (snap) => {
          if (snap && snap.exists && snap.data().isAdmin) {
            authDetails.loaded = true;
            authDetails.user = snap.data();
          } else {
            authDetails.loaded = true;
            authDetails.user = null;
          }
          setAuthDetails({ ...authDetails });
        });
      } else {
        authDetails.loaded = true;
        authDetails.user = null;
        setAuthDetails({ ...authDetails });
      }
    });
  }, [authDetails]);

  function getRoutes() {
    return (
      <AuthContext.Provider value={authDetails}>
        <Router>
          <Switch>
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/"
              component={Home}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/pages/terms"
              component={Terms}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/pages/privacy"
              component={Privacy}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/notifcation/all-users/"
              component={NotificationUsers}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/notifcation/send/:uid"
              component={SendNotification}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/notifcation/group-notification"
              component={GroupNotification}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/notifcation/sentToUsers"
              component={SentToUsers}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/notifcation/sentToGroup"
              component={SentToGroup}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/users/all"
              component={AllUsers}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/users/delete-requests"
              component={DeleteRequests}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/welcomesliders"
              component={WelcomeSlider}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/allads"
              component={AllAds}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/allads/:adKey"
              component={AdsAddUpdate}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/allads/new"
              component={AdsAddUpdate}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/ads/tools/industries"
              component={AdIndustry}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/ads/tools/types"
              component={AdTypes}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/settings/config"
              component={Settings}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/packageslides"
              component={PackagesSlides}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/users/:uid"
              component={UserScreen}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/pages/faq"
              component={FAQ}
            />
            <PrivateRoute
              user={authDetails.user}
              exact
              path="/reports/all"
              component={Reports}
            />
            <Route exact path="/login" component={Login} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }

  if (!authDetails.loaded) {
    return null;
  } else {
    return getRoutes();
  }
}

export default App;
