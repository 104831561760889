import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import { ADMIN_URL, MEMBER_SHIP } from "../../lib/Constants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import Switch from "react-switch";
import Utils from "../../lib/Utils";
import dayjs from "dayjs";
import DateTimePicker from "react-datetime-picker";

class UserScreen extends ParentComponent {
  constructor(props) {
    super(props);
    let { uid } = props.match.params;
    this.state = {
      user: {},
      wallet: {},
      authFields: {},
    };
    this.mounted = true;
    this.ref = firebase.firestore().collection("users").doc(uid);
  }

  async componentDidMount() {
    let { authFields } = this.state;
    this.unsubcribe = this.ref.onSnapshot((snap) => {
      if (snap && snap.exists) {
        let user = snap.data();
        this.updateState({ user, loading: false });
      }
    });
    let { uid } = this.props.match.params;
    if (this.mounted) {
      authFields = await Utils.request(`${ADMIN_URL}/GetUser`, "POST", { uid });
      this.updateState({ authFields });
    }
  }
  componentWillUnmount() {
    if (this.unsubcribe) {
      this.unsubcribe();
    }
  }

  genderValues = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "other" },
  ];

  membership = [
    { value: MEMBER_SHIP?.basic, label: "Basic" },
    { value: MEMBER_SHIP?.adFree, label: "Ad free" },
    { value: MEMBER_SHIP?.gold, label: "Gold" },
    { value: MEMBER_SHIP?.diamond, label: "Diamond" },
  ];

  updateData = (key, e) => {
    let { user, authFields } = this.state;
    if (key === "name") {
      user["name"] = e.target.value;
      authFields["displayName"] = e.target.value;
      this.updateState({ user, authFields });
    }
    if (key === "email") {
      user["email"] = e.target.value;
      authFields["email"] = e.target.value;
      this.updateState({ user, authFields });
    }
    if (key === "phoneNumber") {
      user["phoneNumber"] = e.target.value;
      authFields["phoneNumber"] = e.target.value;
      this.updateState({ user, authFields });
    }
    user[key] = e.target.value;
    this.updateState({ user });
    return;
  };

  _onDateChange = (event) => {
    let { user } = this.state;
    user.membershipExpiresAt = event;
    return this.updateState({ user });
  };

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  onGenderChange = ({ value }) => {
    let { user } = this.state;
    user["gender"] = value;
    this.updateState({ user });
  };

  onMembershipChangeHandler = ({ value }) => {
    let { user } = this.state;
    user["membership"] = value;
    this.updateState({ user });
  };

  handleChange = (key, val) => {
    let { user, authFields } = this.state;
    if (key === "disabled") {
      authFields["disabled"] = val;
      this.updateState({ authFields });
    }
    if (key === "emailVerified") {
      authFields["emailVerified"] = val;
      this.updateState({ authFields });
    }
    user[key] = val;
    this.updateState({ user });
  };

  updateProfile = async () => {
    let { user, authFields } = this.state;
    let { email, phoneNumber } = user;
    if (!email && !phoneNumber) {
      this.showError("Email or phone number field is required");
      return;
    }
    if (email && !Utils.validateEmail(email)) {
      this.showError("Invalid email, please enter a valid email address");
      return;
    }
    this.showLoader();
    let { uid } = this.props.match.params;
    authFields.uid = uid;
    if ((await this.ref.get()).exists) {
      await this.ref.update(user);
    } else {
      await this.ref.set(user);
    }
    const resp = await Utils.request(
      `${ADMIN_URL}/EditUser`,
      "POST",
      authFields
    );
    let { message, error } = resp;
    if (error) {
      this.hideLoader();
      this.showError("Error occured, please contact system administrator");
      return;
    }
    if (message) {
      this.hideLoader();
    }
    this.showSuccess("Updated user record successfully");
  };

  render() {
    let { user, authFields } = this.state;
    let { metadata, providerData, emailVerified } = authFields;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s12 m4">
            <div className="card">
              <div className="card-content">
                <div className="m-t-30">
                  <strong>Last Login:</strong>
                  <h6>
                    {" "}
                    {dayjs(metadata?.lastSignInTime).format(
                      "DD-MM-YYYY hh:mm:ss:A Z"
                    ) ?? "N/A"}
                  </h6>
                  <strong>Created At:</strong>{" "}
                  <h6>
                    {dayjs(metadata?.creationTime).format(
                      "DD-MM-YYYY hh:mm:ss:A Z"
                    )}
                  </h6>
                  <strong>Email Verified: </strong>
                  <h6>{emailVerified ? "Yes" : "No"}</h6>
                  {user?.membership !== MEMBER_SHIP?.basic && (
                    <>
                      <strong>Membership Expires: </strong>
                      <h6>
                        {user?.membershipExpiresAt
                          ? dayjs(user?.membershipExpiresAt).format(
                              "DD-MM-YYYY hh:mm:ss:A Z"
                            )
                          : "N/A"}
                      </h6>
                    </>
                  )}
                </div>
              </div>
              <hr />
              <div className="card-content">
                <small>Email address </small>
                {providerData?.map((data) => (
                  <h6 key={`${data?.uid}`}>{data?.email ?? "N/A"}</h6>
                ))}
                <small>Phone</small>
                {providerData?.map((data) => (
                  <h6 key={`${data?.uid}`}>{data?.phoneNumber ?? "N/A"}</h6>
                ))}
              </div>
            </div>
          </div>
          <div className="col s12 m8">
            <div className="card">
              <div className="row">
                <div className="col s12">
                  <Tabs>
                    <TabList>
                      <Tab>Settings</Tab>
                    </TabList>
                    <TabPanel>
                      <div id="settings" className="col s12">
                        <div className="card-content">
                          <div className="row">
                            <div className="input-field col s12">
                              <input
                                value={this.state.user?.topic}
                                id={"topic"}
                                type="text"
                                className="validate"
                                onChange={this.updateData.bind(this, "topic")}
                              />
                              <label
                                className={
                                  this.state.user?.topic ? "active" : ""
                                }
                                htmlFor={"topic"}
                              >
                                Topic
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <input
                                value={this.state.user?.email}
                                id={"email"}
                                type="text"
                                className="validate"
                                onChange={this.updateData.bind(this, "email")}
                              />
                              <label
                                className={user?.email ? "active" : ""}
                                htmlFor={"email"}
                              >
                                Email
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <input
                                value={this.state.user?.phoneNumber}
                                id={"phoneNumber"}
                                type="text"
                                className="validate"
                                onChange={this.updateData.bind(
                                  this,
                                  "phoneNumber"
                                )}
                              />
                              <label
                                className={
                                  this.state.user?.phoneNumber ? "active" : ""
                                }
                                htmlFor={"phoneNumber"}
                              >
                                Phone Number
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <Select
                                placeholder={"Gender"}
                                value={this.genderValues.filter(
                                  (gender) => gender.value === user?.gender
                                )}
                                id={"gender"}
                                onChange={this.onGenderChange}
                                options={this.genderValues}
                                className={"m-t-20"}
                                isSearchable={false}
                              />
                              <label
                                className={user?.gender ? "active" : ""}
                                htmlFor={"gender"}
                              >
                                Gender
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <Select
                                placeholder={"Membership"}
                                value={this.membership.filter(
                                  (gender) => gender.value === user?.membership
                                )}
                                id={"membership"}
                                onChange={this.onMembershipChangeHandler}
                                options={this.membership}
                                className={"m-t-20"}
                                isSearchable={false}
                              />
                              <label
                                className={user?.membership ? "active" : ""}
                                htmlFor={"membership"}
                              >
                                Membership
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <DateTimePicker
                                id={"membershipExpiresAt"}
                                value={
                                  user?.membershipExpiresAt?.hasOwnProperty(
                                    "seconds"
                                  )
                                    ? user?.membershipExpiresAt.toDate()
                                    : user?.membershipExpiresAt
                                }
                                minDate={new Date()}
                                onChange={this._onDateChange}
                                format={"dd.MM.yyyy hh:mm a"}
                                locale="en-US"
                                className={"m-t-20"}
                              />
                              <label
                                className={user?.membership ? "active" : ""}
                                htmlFor={"membershipExpiresAt"}
                              >
                                Membership Expires At
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12 flexRow justify-content-space">
                              <span className={"m-t-15"}>
                                User Discoverable (Show on Dim)
                              </span>
                              <Switch
                                onChange={this.handleChange.bind(
                                  this,
                                  "isPublic"
                                )}
                                checked={this.state?.user?.isPublic ?? true}
                                id={"isPublic"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12 flexRow justify-content-space">
                              <span className={"m-t-15"}>Email Verified</span>
                              <Switch
                                onChange={this.handleChange.bind(
                                  this,
                                  "emailVerified"
                                )}
                                checked={
                                  this.state?.user?.emailVerified ?? false
                                }
                                id={"emailVerified"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12 flexRow justify-content-space">
                              <span className={"m-t-15"}>Show Age</span>
                              <Switch
                                onChange={this.handleChange.bind(
                                  this,
                                  "isAgeVisible"
                                )}
                                checked={
                                  this.state?.user?.isAgeVisible ?? false
                                }
                                id={"isAgeVisible"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12 flexRow justify-content-space">
                              <span className={"m-t-15"}>Show Gender</span>
                              <Switch
                                onChange={this.handleChange.bind(
                                  this,
                                  "showMyGender"
                                )}
                                checked={
                                  this.state?.user?.showMyGender ?? false
                                }
                                id={"showMyGender"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12 flexRow justify-content-space">
                              <span className={"m-t-15"}>
                                Show Membership Badge
                              </span>
                              <Switch
                                onChange={this.handleChange.bind(
                                  this,
                                  "showMembershipBadge"
                                )}
                                checked={
                                  this.state?.user?.showMembershipBadge ?? false
                                }
                                id={"showMembershipBadge"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12 flexRow justify-content-space">
                              <span className={"m-t-15"}>Disabled</span>
                              <Switch
                                onChange={this.handleChange.bind(
                                  this,
                                  "disabled"
                                )}
                                checked={
                                  this.state?.authFields?.disabled ?? false
                                }
                                id={"disabled"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <button
                                className="waves-effect waves-light btn indigo"
                                onClick={this.updateProfile}
                              >
                                Update Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserScreen;
