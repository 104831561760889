import React, { useState } from "react";
import EmojiPicker from "./Picker";

const EmojiButton = (props) => {
  return (
    <button
      type="button"
      className={`react-input-emoji--button ${
        props?.selected && "react-input-emoji--button__show"
      }`}
      onClick={props?.onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        className="react-input-emoji--button--icon"
      >
        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path>
        <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path>
      </svg>
    </button>
  );
};

const InputEmoji = ({ placeholder = "Message", ...props }) => {
  const [showEmojis, setShowEmojis] = useState(false);

  const _onEmojiButton = () => {
    setShowEmojis(!showEmojis);
  };

  return (
    <div className="row flex-row align-items-center">
      <div className="col s11">
        {props?.type === "textarea" ? (
          <textarea
            className="materialize-textarea"
            value={props.value}
            onChange={(e) => props.onChange(e)}
            type="text"
            placeholder={placeholder}
          />
        ) : (
          <input
            className="materialize-textarea"
            value={props.value}
            onChange={(e) => props.onChange(e)}
            type="text"
            placeholder={placeholder}
          />
        )}
      </div>
      <EmojiButton onClick={_onEmojiButton} selected={showEmojis} />
      {showEmojis && (
        <EmojiPicker
          theme={"auto"}
          color=""
          onEmojiSelect={props?.onEmojiSelect}
          title=""
          emoji=""
          showPreview={false}
          showSkinTones={false}
          style={
            props.type === "textarea" ? { position: "absolute", right: 10 } : {}
          }
        />
      )}
    </div>
  );
};

export default InputEmoji;
