/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { auth } from "../../lib/Firebase";
import { AuthContext } from "../../lib/AuthContext";

export default class Header extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      user: context.user,
    };
  }

  componentDidMount() {
    window.$(".sidebar-toggle").on("click", function () {
      window.$("#main-wrapper").toggleClass("show-sidebar");
    });
  }

  componentWillUnmount() {}

  logout = async () => {
    await auth.signOut();
    window.location.href = "/login";
  };

  render() {
    let { user } = this.state;
    return (
      <header className="topbar">
        <nav data-navbarbg="skin6">
          <div className="nav-wrapper">
            <a className="brand-logo" data-logobg="skin5">
              <span className="icon"></span>
              <span className="text">DimJack Admin</span>
            </a>
            <ul className="left">
              <li className="hide-on-med-and-down">
                <a className="nav-toggle">
                  <span className="bars bar1"></span>
                  <span className="bars bar2"></span>
                  <span className="bars bar3"></span>
                </a>
              </li>
              <li className="hide-on-large-only">
                <a className="sidebar-toggle">
                  <span className="bars bar1"></span>
                  <span className="bars bar2"></span>
                  <span className="bars bar3"></span>
                </a>
              </li>
            </ul>
            <ul className="right">
              <li>
                <a className="dropdown-trigger" data-target="user_dropdown">
                  <img
                    src={user.profileAvtar}
                    alt={user.name}
                    className="circle object-fit-cover profile-pic"
                  />
                </a>
                <ul
                  id="user_dropdown"
                  className="mailbox dropdown-content dropdown-user"
                >
                  <li>
                    <div className="dw-user-box">
                      <div className="u-img">
                        <img src={user.profileAvtar} alt={user.name} />
                      </div>
                      <div className="u-text">
                        <h4>{user.name}</h4>
                        <p>{user.email}</p>
                        <button
                          onClick={this.logout}
                          className="waves-effect waves-light btn-small red white-text"
                        >
                          Sign Out
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
