import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import InputEmoji from "../../components/InputEmoji";
import Select from "react-select";
import { NOTIFICATION_TYPE } from "../../lib/Constants";

const customSelectStyle = {
  control: (provided, _) => ({
    ...provided,
    height: "40px",
    width: "200px",
  }),
  valueContainer: (provided, _) => ({
    ...provided,
    height: "40px",
    top: "-10px",
  }),
};

class SendNotification extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: "",
      title: "",
      imageURL: "",
      plainImageURL: "",
      imageFile: null,
      users: [],
      group: true,
      type: "",
      backgroundColor: null,
      icon: null,
      iconBackground: "#2ED3FF",
    };
    this.ref = firebase.firestore().collection("users");
  }

  notificationType = [
    { value: NOTIFICATION_TYPE.account, label: "Account" },
    { value: NOTIFICATION_TYPE.announcement, label: "Annoucement" },
    { value: NOTIFICATION_TYPE.misc, label: "Misc" },
    { value: NOTIFICATION_TYPE.system, label: "System" },
  ];

  _options = [
    { value: true, label: "Purchased Users" },
    { value: false, label: "Normal Users" },
    { value: "allusers", label: "All Users" },
  ];

  getUsers = async () => {
    let { group } = this.state;
    let users = [];
    this.updateState({ loading: true });
    if (group !== "allusers") {
      let dbUsers = await this.ref.where("isPro", "==", group).get();
      dbUsers?.forEach((user) => {
        if (user?.exists) {
          const uid = user?.data()?.uid;
          if (!uid || uid === "") {
            return;
          }
          users?.push(uid);
        }
      });
    } else {
      let dbUsers = await this.ref.get();
      dbUsers?.forEach((user) => {
        if (user?.exists) {
          const uid = user?.data()?.uid;
          if (!uid || uid === "") {
            return;
          }
          users?.push(uid);
        }
      });
    }
    this.updateState({ loading: false });
    return users;
  };

  async componentDidMount() {
    let users = await this.getUsers();
    this.updateState({ users });
  }

  async componentDidUpdate(prevProps, prevState) {
    let _group = prevState?.group;
    let { group } = this.state;
    if (_group !== group) {
      let users = await this.getUsers();
      this.updateState({ users });
    }
  }

  onNotificationTypeChange = ({ value }) => {
    this.updateState({ type: value });
  };

  onValueChange = (val) => {
    this.updateState({ group: val?.value });
  };

  showFilePicker = () => {
    this.fileEle?.click();
  };

  handleChange = (key, e) => {
    if (key === "imageURL") {
      let objURL = URL.createObjectURL(e.target.files[0]);
      this.updateState({ imageURL: objURL });
      this.updateState({ imageURL: objURL });
      this.updateState({
        imageFile: e.target.files && e.target.files[0] ? e.target.files[0] : "",
      });
    } else if (key === "plainImageURL") {
      this.updateState({ imageURL: e?.target?.value });
    } else {
      this.updateState({ [key]: e?.target?.value });
    }
  };

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  uploadFileAndGetDownloadURL = async (imageURL, id) => {
    await firebase.storage().ref("notifications").child(id).put(imageURL);
    let downloadURL = await firebase
      .storage()
      .ref("notifications")
      .child(id)
      .getDownloadURL();
    return downloadURL;
  };

  appendEmoji = (e, key) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    this.updateState({ [key]: this.state?.[key] + emoji });
  };

  validate = () => {
    let valid = true;
    let { title, message } = this.state;
    if (!title || !message) {
      valid = false;
    }
    return valid;
  };

  sendNotification = async () => {
    const notificationRef = firebase
      .firestore()
      .collection("notifications")
      .doc("group-notifications")
      .collection("notifications")
      .doc();
    const notificationID = notificationRef.id;
    let validateData = this.validate();
    if (!validateData) {
      this.showError("Please fill title or message");
      return;
    }
    this.updateState({ loading: true });
    this.showLoader();
    let {
      title,
      message,
      imageFile,
      users,
      type,
      icon,
      backgroundColor,
      iconBackground,
    } = this.state;
    let image;
    if (imageFile) {
      image = await this.uploadFileAndGetDownloadURL(imageFile, notificationID);
    }
    let createdAt = firebase.firestore.FieldValue.serverTimestamp();
    let data = {
      title: title ?? null,
      users,
      message: message ?? null,
      imageURL: image ?? null,
      createdAt,
      resend: true,
      resendedTimes: 0,
      type: type ?? null,
      key: notificationID,
      backgroundColor: backgroundColor ?? null,
      icon: icon ?? null,
      iconBackground: iconBackground ?? null,
    };
    await notificationRef.set(data, { merge: true });
    this.hideLoader();
    this.showSuccess("Notification sent successfully to server");
    this.updateState({ loading: false });
  };

  render() {
    let { users, loading } = this.state;
    return (
      <div className="container-fluid">
        <div className="col s12">
          <h4>Send Notifcation</h4>
        </div>
        <div className="card">
          <div className="card-content">
            <div className="row">
              <div className="col s6">
                <span>Send Notification</span>
              </div>
              <div className="col s6">
                <div className="col s6">
                  <Select
                    isMulti={false}
                    onChange={this.onValueChange}
                    options={this._options}
                    isSearchable={false}
                    placeholder={"Select Group"}
                    styles={customSelectStyle}
                  />
                </div>
              </div>
            </div>
            {loading && (
              <div className="m-t-20 text-center">
                <i className="fa fa-spinner fa-spin fa-2x" />
              </div>
            )}
            <span>Total Users in Group &gt;&gt; {`${users?.length}`}</span>
            <div className="input-field col s12">
              <Select
                placeholder={"Type"}
                id={"type"}
                onChange={this.onNotificationTypeChange}
                options={this.notificationType}
                isSearchable={false}
              />
            </div>
            <div className="m-t-20">
              <label>Notification Title</label>
              <div className="row">
                <div className="col s6">
                  <InputEmoji
                    value={this.state.title}
                    onChange={this.handleChange.bind(this, "title")}
                    onEmojiSelect={(e) => this.appendEmoji(e, "title")}
                  />
                </div>
                <div className="col s6">
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className="d-flex flex-column upload-thumbnail jcac border-gray p-10 pointer"
                      onClick={this.showFilePicker}
                    >
                      {!this.state.imageURL && (
                        <>
                          <i className="fa fa-cloud-upload-alt fa-2x"></i>
                        </>
                      )}
                      {this.state.imageURL && (
                        <img
                          width="70"
                          height="70"
                          src={this.state.imageURL}
                          alt="Option"
                        />
                      )}
                    </div>
                    <input
                      value={this.state.imageURL}
                      placeholder="Image URL"
                      type="text"
                      className="validate h2rem"
                      onChange={this.handleChange.bind(this, "plainImageURL")}
                    />
                    <input
                      ref={(input) => (this.fileEle = input)}
                      type="file"
                      className="d-none"
                      onChange={this.handleChange.bind(this, "imageURL")}
                    />
                  </div>
                </div>
              </div>
              <div className="m-t-20">
                <label>Notification Message</label>
                <InputEmoji
                  value={this.state.message}
                  onChange={this.handleChange.bind(this, "message")}
                  onEmojiSelect={(e) => this.appendEmoji(e, "message")}
                  type="textarea"
                />
              </div>
            </div>
            <div className="m-t-10">
              <label className="relative">
                Background Color &nbsp;&nbsp;
                <input
                  className="basic"
                  type="text"
                  id={"backgroundColor"}
                  value={this.state.backgroundColor}
                  onChange={this.handleChange.bind(this, "backgroundColor")}
                />
                <input
                  type="color"
                  id={`backgroundColor`}
                  value={this.state.backgroundColor}
                  onChange={this.handleChange.bind(this, "backgroundColor")}
                />
              </label>
            </div>
            <div className={"d-flex flex-row align-items-center"}>
              <i className="material-icons m-r-10">info</i>
              <small className={"small"}>
                background color for popup message in the app, if leave blank
                the default system bacground color will be used
              </small>
            </div>
            <div className={"d-flex flex-row align-items-center m-t-20"}>
              <div className="m-r-20">
                <label className="relative">
                  Icon &nbsp;&nbsp;
                  <input
                    className="basic"
                    type="text"
                    id={"icon"}
                    value={this.state.icon}
                    onChange={this.handleChange.bind(this, "icon")}
                  />
                </label>
              </div>
              <div className="m-l-20">
                <label className="relative">
                  Icon Background &nbsp;&nbsp;
                  <input
                    className="basic"
                    type="text"
                    id={"iconBackground"}
                    value={this.state.iconBackground}
                    onChange={this.handleChange.bind(this, "iconBackground")}
                  />
                  <input
                    type="color"
                    id={`iconBackground`}
                    value={this.state.iconBackground}
                    onChange={this.handleChange.bind(this, "iconBackground")}
                  />
                </label>
              </div>
            </div>
            <div className={"d-flex flex-row align-items-center"}>
              <i className="material-icons m-r-10">info</i>
              <small className={"small"}>
                Please note that the icon must be from{" "}
                <a href="https://ionic.io/ionicons">ionicons</a> if supplied
                invalid icons, then the system default icon would be used
                instead.
                <br />
                Also note that the Icon background color is only used in the
                System Notification screen, if a background color is not
                supplied the system default color i.e.: #2ED3FF would be used
                instead.
              </small>
            </div>
            <div className="row m-t-20">
              <div className="col s6">
                <button
                  className="btn-large w100 blue"
                  onClick={this.sendNotification}
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SendNotification;
