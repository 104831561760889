import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import dayjs from "dayjs";
import Utils from "../../lib/Utils";
import { CSVLink } from "react-csv";
import { ColorRing } from "react-loader-spinner";

class AllAds extends ParentComponent {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      ads: [],
      loading: true,
      data: {},
      csvData: [],
    };
    this.colRef = firebase.firestore().collection("ads");
  }

  async componentDidMount() {
    let $ = window.$;
    this.unsubscribe = this.colRef.onSnapshot((snap) => {
      const { convertToCSV } = Utils;
      let ads = [];
      snap.docs.forEach((snap) => {
        if (!snap.exists) {
          return;
        }
        // eslint-disable-next-line eqeqeq
        if (snap.id == "types" || snap.id == "industries") {
          return;
        }
        ads.push(snap.data());
      });
      const csvData = convertToCSV(ads);
      let loading = this.state.loading;
      this.updateState({ ads, csvData, loading: false }, () => {
        $(".tooltipped").tooltip({ outDuration: 0 });
        if (loading) {
          $(".modal").modal();
        }
      });
    });
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    this.mounted = false;
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  loadOther = (val, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    this.props.history.push(`/allads/${val}`);
  };

  loadNew = () => {
    this.props.history.push(`/allads/new`);
  };

  deleteAd = async () => {
    let { current } = this.state;
    if (current) {
      this.showLoader();
      let { key, businessName } = current;
      try {
        await firebase.firestore().collection("ads").doc(key).delete();
        await firebase
          .storage()
          .ref(`images/ads/${key}/${businessName}`)
          .delete();
      } catch (error) {
        console.log("Err => ", error);
      }
      this.hideLoader();
      this.showSuccess("Ad deleted successfully");
    }
  };

  setCurrent = (item) => {
    this.updateState({ current: item });
  };

  showFilePicker = () => {
    this.fileEle.click();
  };

  updatefield = async (key, e) => {
    let { data } = this.state;
    if (key === "active") {
      this.updateState({ data: { ...data, [key]: e } });
    }
    if (key === "businessLogo") {
      const file = e.target.files && e.target.files[0] ? e.target.files[0] : "";
      const objURL = URL.createObjectURL(file);
      this.updateState({ businessLogo: objURL });
    } else {
      this.updateState({ data: { ...data, [key]: e.target.value } });
    }
  };

  changeCoordinates = async (key, e) => {
    const { data } = this.state;
    const { coordinates } = data;
    this.updateState({
      data: { ...data, coordinates: { ...coordinates, [key]: e.target.value } },
    });
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    All Ads ({dataArray ? dataArray.length : 0})
                  </h5>
                  <small>Click to View details</small>
                </div>
                <div className={"col s2 text-right"}>
                  {!!this.state.csvData && (
                    <CSVLink
                      ref={this.csvLink}
                      data={this.state.csvData}
                      enclosingCharacter={`"`}
                      filename={`deleteRequests_${new Date().getMilliseconds()}`}
                      className="btn waves-effect waves-light btn-round blue modal-trigger"
                    >
                      Export Data
                    </CSVLink>
                  )}
                </div>
                <div className="col s2 text-right">
                  <button
                    className="btn waves-effect waves-light btn-round blue modal-trigger"
                    onClick={this.loadNew}
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table
                id="table"
                className="table m-t-10 highlight toggle-circle"
                data-page-size="20"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Business</th>
                    <th>Type</th>
                    <th>Coordinates</th>
                    <th>Created At</th>
                    <th>Status</th>
                    <th data-sort-ignore="true" className="min-width">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <ColorRing
                      visible={true}
                      height="100"
                      width="100"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={[
                        "#FF2E63",
                        "#2ED3FF",
                        "#5FC88F",
                        "#DF9F28",
                        "#767DFF",
                      ]}
                    />
                  )}
                  {!loading && dataArray.length === 0 && (
                    <tr>
                      <td colSpan="6">
                        This section is currently empty
                        <br />
                        Consider adding new
                      </td>
                    </tr>
                  )}
                  {dataArray.map((val, i) => {
                    return (
                      <tr
                        key={`${val?.key ?? i}`}
                        className={"hovertr pointer"}
                      >
                        <td>{i + 1}</td>
                        <td>
                          <div className="chip">
                            <img
                              src={val?.businessLogo}
                              alt={`${val?.businessName}_logo`}
                            />
                            <span>{val?.businessName}</span>
                          </div>
                        </td>
                        <td>
                          <span>
                            {typeof val?.type == "object"
                              ? val?.type[0]?.value
                              : val?.type}
                          </span>
                        </td>
                        <td>
                          <div class={"d-flex flex-column align-items-center"}>
                            <span>{`Latitude: ${val?.coordinates?.latitude}`}</span>
                            <span>{`Longitude: ${val?.coordinates?.longitude}`}</span>
                          </div>
                        </td>
                        <td>
                          {dayjs(val?.createdAt?.toDate()).format(
                            "DD/MM/YYYY/ hh:mm:a"
                          )}
                        </td>
                        <td>
                          {val?.active ? (
                            <span className="label label-success">Yes</span>
                          ) : (
                            <span className="label label-warning">No</span>
                          )}
                        </td>
                        <td>
                          <i
                            data-position="top"
                            data-tooltip="Edit"
                            className="tooltipped p-5 pointer fas fa-pencil-alt fa-lg indigo-text"
                            onClick={this.loadOther.bind(this, val?.key)}
                          />
                          <i
                            data-position="top"
                            data-tooltip="Delete"
                            className="tooltipped fas fa-trash fa-lg materialize-red-text pointer modal-trigger"
                            data-target="deletionconfirm"
                            onClick={this.setCurrent.bind(this, val)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7">
                      <div className="right-align">
                        <ul className="pagination">
                          {" "}
                          <li className="footable-page-arrow disabled">
                            <a data-page="first" href="#first">
                              «
                            </a>
                          </li>
                          <li className="footable-page-arrow disabled">
                            <a data-page="prev" href="#prev">
                              ‹
                            </a>
                          </li>
                          <li className="footable-page-arrow">
                            <a data-page="next" href="#next">
                              ›
                            </a>
                          </li>
                          <li className="footable-page-arrow">
                            <a data-page="last" href="#last">
                              »
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { ads } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">{this.getTableData(ads)}</div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>You sure to delete this user, you won't be able to undo it.</p>
          </div>
          <div className="modal-footer">
            <button className="modal-action modal-close waves-effect waves-green btn-flat">
              Cancel
            </button>
            <button
              className="modal-close btn waves-effect waves-light btn-round blue"
              onClick={this.deleteAd}
            >
              Yes, I'm Sure
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AllAds;
