import ParentComponent from "../../lib/ParentComponent";
import { withRouter } from "react-router-dom";
import firebase, { auth } from "../../lib/Firebase";

class Login extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    window.$(".tooltipped").tooltip();
  }

  loginUsingEmail = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    var email = this.email.value;
    var password = this.password.value;
    auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.checkAdmin(result);
      })
      .catch((error) => {
        this.showError(error.message);
      });
  };

  checkAdmin = (result) => {
    const uid = result.user.uid;
    let userPrivateRef = firebase.firestore().collection("users").doc(uid);
    userPrivateRef.get().then((snap) => {
      if (snap && snap.exists && snap.data().isAdmin) {
        window.location.href = "/";
      } else {
        this.showError("Invalid credentials supplied");
      }
    });
  };

  render() {
    return (
      <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
        <div className="container">
          <div className="row">
            <div className="col s12 l8 m6 demo-text">
              <h1 className="font-light m-t-40">
                Welcome to the{" "}
                <span className="font-medium black-text">DimJack Admin</span>
              </h1>
              <p>Login to make changes to your app.</p>
            </div>
          </div>
          <div className="auth-box auth-sidebar">
            <div id="loginform">
              <div className="p-l-10">
                <h5 className="font-medium m-b-0 m-t-40">Sign In to Admin</h5>
                <small>Just login to your account</small>
              </div>
              <div className="row">
                <form
                  className="col s12 m-t-20"
                  action="#"
                  autoComplete="false"
                  onSubmit={this.loginUsingEmail}
                >
                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="email"
                        ref={(ele) => (this.email = ele)}
                        type="email"
                        className="validate"
                        required
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="password"
                        ref={(ele) => (this.password = ele)}
                        type="password"
                        className="validate"
                        required
                      />
                      <label htmlFor="password">Password</label>
                    </div>
                  </div>
                  <div className="row m-t-40">
                    <div className="col s12">
                      <button
                        className="btn-large w100 blue accent-4"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div id="recoverform">
              <div className="p-l-10">
                <h5 className="font-medium m-b-0 m-t-40">Recover password</h5>
                <small>
                  Enter your Email and instructions will be sent to you!
                </small>
              </div>
              <div className="row">
                <form className="col s12" action="index.html">
                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="email1"
                        type="email"
                        className="validate"
                        required
                      />
                      <label htmlFor="email1">Email</label>
                    </div>
                  </div>
                  <div className="row m-t-20">
                    <div className="col s12">
                      <button
                        className="btn-large w100 red"
                        type="submit"
                        name="action"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
