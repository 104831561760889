import React from "react";
import firebase from "../../lib/Firebase";
import { AuthContext } from "../../lib/AuthContext";
import ParentComponent from "../../lib/ParentComponent";

export default class Settings extends ParentComponent {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      coordinates: [],
      newdata: {},
      currentIndex: null,
      loading: true,
      coordinate: null,
    };
    this.typesRef = firebase
      .firestore()
      .collection("settings")
      .doc("coordinates");
  }

  async componentDidMount() {
    let $ = window.$;
    this.unsubscribe = this.typesRef.onSnapshot((snap) => {
      let coordinates;
      if (snap && snap.exists) {
        const data = snap?.data();
        if (data?.coordinates) {
          coordinates = data?.coordinates;
        }
      }

      this.updateState({ coordinates, loading: false }, () => {
        $(".tooltipped").tooltip({ outDuration: 0 });
      });
      $(".modal").modal();
    });
  }

  componentWillUnmount() {
    let $ = window.$;
    $(".tooltipped").tooltip("close");
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  isValidLatAndLong = (lat, lon) => {
    const num1 = "" + lat; //convert toString
    const num2 = "" + lon;
    if (
      parseFloat(num1) <= 90 &&
      parseFloat(num1) >= -90 &&
      parseFloat(num2) <= 180 &&
      parseFloat(num2) >= -180
    ) {
      return true;
    } else {
      return false;
    }
  };

  delete = async (item) => {
    if (item) {
      this.showLoader();
      await this.typesRef.update({
        coordinates: firebase.firestore.FieldValue.arrayRemove(item),
      });
      this.hideLoader();
    }
    this.showSuccess("Coordinates removed successfully");
  };

  updateValues = (key, e) => {
    const { coordinate = {} } = this.state;
    coordinate[key] = e.target.value;
    this.updateState({ coordinate });
  };

  updateNewValues = (key, e) => {
    let { newdata = {} } = this.state;
    newdata[key] = e.target.value;
    this.updateState({ newdata });
  };

  saveNew = async () => {
    let { newdata = {}, coordinates } = this.state;
    let isExist = coordinates.find(
      (cat) =>
        cat.longitude === newdata.longitude && cat.latitude === newdata.latitude
    );
    if (isExist) {
      this.showError("Coordinates already exist");
      return;
    }
    if (!this.isValidLatAndLong(newdata?.latitude, newdata?.longitude)) {
      return this.showError(
        "Invalid coordinates, please try with valid coordinates"
      );
    }
    if (newdata?.latitude && newdata?.longitude) {
      this.showLoader();
      await this.typesRef.set(
        { coordinates: firebase.firestore.FieldValue.arrayUnion(newdata) },
        { merge: true }
      );
      this.hideLoader();
      this.showSuccess("Type added successfully");
    } else {
      this.showError("Please fill in all details");
    }
  };

  setCurrent = (index, coordinate) => {
    this.setState({ currentIndex: index, coordinate });
  };

  update = async () => {
    const { currentIndex, coordinates, coordinate } = this.state;
    let { latitude, longitude } = coordinate;
    let valid = true;
    if (!latitude && !longitude) {
      valid = false;
    }
    if (!valid) {
      this.showError("Some fields are missing");
      return false;
    }
    coordinates[currentIndex] = coordinate;
    //for all those whose images has been changed, upload the image to storage
    this.showLoader();

    await this.typesRef.update({ coordinates });
    this.hideLoader();
    this.showSuccess(" updated successfully");
  };

  renderEditModal = () => {
    const { coordinate } = this.state;
    return (
      <div className="modal-content">
        <h5>Editing Type</h5>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col s6">
            <div className="input-field">
              <input
                value={coordinate?.latitude || ""}
                id="value"
                type="text"
                className="validate"
                onChange={this.updateValues.bind(this, "latitude")}
              />
              <label
                className={coordinate?.latitude ? "active" : ""}
                htmlFor="value"
              >
                Latitude
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={coordinate?.longitude || ""}
                id="label"
                type="text"
                className="validate"
                onChange={this.updateValues.bind(this, "longitude")}
              />
              <label
                className={coordinate?.longitude ? "active" : ""}
                htmlFor="label"
              >
                Longitutde
              </label>
            </div>
          </div>
        </div>
        <div className="text-right m-t-20">
          <button className="modal-action modal-close waves-effect waves-green btn-flat">
            Cancel
          </button>
          <button
            className="modal-close btn waves-effect waves-light btn-round blue"
            onClick={this.update}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  renderNewModal = () => {
    let { newdata } = this.state;
    return (
      <div className="modal-content">
        <h5>Add Type</h5>
        <p>&nbsp;</p>
        <div className="row">
          <div className="col s6">
            <div className="input-field">
              <input
                value={newdata.latitude || ""}
                id="newlat"
                type="text"
                className="validate"
                onChange={this.updateNewValues.bind(this, "latitude")}
              />
              <label
                className={newdata.latitude ? "active" : ""}
                htmlFor="newlat"
              >
                Latitude
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                value={newdata.longitude || ""}
                id="newlong"
                type="text"
                className="validate"
                onChange={this.updateNewValues.bind(this, "longitude")}
              />
              <label
                className={newdata.longitude ? "active" : ""}
                htmlFor="newlong"
              >
                Longitutde
              </label>
            </div>
          </div>
        </div>
        <div className="text-right m-t-20">
          <button className="modal-action modal-close waves-effect waves-green btn-flat">
            Cancel
          </button>
          <button
            className="modal-close btn waves-effect waves-light btn-round blue"
            onClick={this.saveNew}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  getTableData = (dataArray = []) => {
    let { loading } = this.state;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="">
              <div className="row">
                <div className="col s8">
                  <h5 className="card-title m-b-0">
                    Coordinates ({dataArray ? dataArray.length : 0})
                  </h5>
                </div>
              </div>
            </div>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading && dataArray.length === 0 && (
                  <tr>
                    <td colSpan="6">
                      No coordinates are found, consider adding a new coordinate
                    </td>
                  </tr>
                )}
                {dataArray.map((val, i) => {
                  return (
                    <tr key={`${i}`} className={""}>
                      <td>{val?.latitude}</td>
                      <td>{val?.longitude}</td>
                      <td>
                        <i
                          data-position="top"
                          data-tooltip="Delete"
                          className="tooltipped material-icons p-5 pointer modal-trigger"
                          data-target="deletionconfirm"
                          onClick={this.setCurrent.bind(this, i, val)}
                        >
                          delete
                        </i>
                        <i
                          data-position="top"
                          data-tooltip="Edit"
                          className="tooltipped material-icons p-5 pointer modal-trigger"
                          data-target="editmodal"
                          onClick={this.setCurrent.bind(this, i, val)}
                        >
                          edit
                        </i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { coordinates, loading, coordinate } = this.state;
    if (loading) {
      return null;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s10">
            <h4>App Coordinates</h4>
            <p className="small">
              This is a list of coordinates from which a random coordinate will
              be selected when user refused to share his location.
            </p>
          </div>
          <div className="col s2 text-right">
            <button
              className="btn btn-primary d-block mx-auto modal-trigger"
              data-target="newmodal"
            >
              Add New
            </button>
          </div>
        </div>
        <div className="row">{this.getTableData(coordinates)}</div>
        <div id="deletionconfirm" className="modal">
          <div className="modal-content">
            <h4>Are you sure ?</h4>
            <p>
              You are sure to delete this category, you won't be able to undo
              it.
            </p>
            <div className="text-right m-t-20">
              <button className="modal-action modal-close waves-effect waves-green btn-flat">
                Cancel
              </button>
              <button
                className="modal-close btn waves-effect waves-light btn-round blue"
                onClick={this.delete.bind(this, coordinate)}
              >
                Yes, I'm Sure
              </button>
            </div>
          </div>
        </div>
        <div id="editmodal" className="modal">
          {this.renderEditModal()}
        </div>
        <div id="newmodal" className="modal">
          {this.renderNewModal()}
        </div>
      </div>
    );
  }
}
