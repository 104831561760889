export const ADMIN_URL =
  "https://us-central1-dimjack-a954d.cloudfunctions.net/app";
// "http://127.0.0.1:5001/dimjack-a954d/us-central1/app";

export const PAYMENT_STATUS = {
  in_review: "In Review",
  processing: "Processing",
  processed: "Processed",
  decline: "Declined",
};

export const MEMBER_SHIP = {
  gold: "gold",
  diamond: "diamond",
  adFree: "adfree",
  basic: "basic",
};

export const DELETE_REQUEST_STATUS = {
  onhold: "onHold",
  completed: "completed",
  cancelled: "cancelled",
  processing: "processing",
};

export const DELETE_REQ_STATUS_LABEL = {
  onhold: "OnHold",
  completed: "Completed",
  cancelled: "Cancelled",
  processing: "Processing",
};

export const NOTIFICATION_TYPE = {
  announcement: "announcement",
  account: "account",
  system: "system",
  misc: "misc",
};
