import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";
import { Editor } from "@tinymce/tinymce-react";

class Privacy extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        text: null,
      },
      loading: true,
    };
    this.ref = firebase.firestore().doc("pages/privacy");
    this.mounted = true;
  }

  async componentDidMount() {
    this.unsubcribe = this.ref.onSnapshot((snap) => {
      if (snap && snap.exists) {
        let data = snap.data();
        this.updateState({ data, loading: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubcribe) {
      this.unsubcribe();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  save = async () => {
    let { data } = this.state;
    this.showLoader();
    if (data) {
      await this.ref.set(data, { merge: true });
      this.showSuccess("Privacy Policy updated successfully");
    } else {
      this.showError("Please enter some value");
    }
    this.hideLoader();
  };

  handleEditorChange = (value) => {
    this.updateState({
      data: {
        text: value,
      },
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s10">
            <h4>Privacy Policy</h4>
          </div>
          <div className="col s2 text-right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.save}
            >
              Save
            </button>
          </div>
        </div>
        <div className="m-t-20">
          <Editor
            value={this.state.data?.text}
            apiKey={"48oyhqjb3s0mv9m88rzw5733ra3liaoot8ozxrnoymrcd787"}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "h1 h2 h3 h4 h5 h6 fontsizeselect | " +
                "bold italic underline blockquote | backcolor forecolor  | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onEditorChange={this.handleEditorChange}
            tinymceScriptSrc=""
          />
        </div>
      </div>
    );
  }
}

export default Privacy;
