import ParentComponent from "../../lib/ParentComponent";
import firebase from "../../lib/Firebase";

class FAQ extends ParentComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
    this.ref = firebase.firestore().doc("pages/faq");
  }

  async componentDidMount() {
    this.unsubcribe = this.ref.onSnapshot((snap) => {
      if (snap && snap.exists) {
        let { data = [] } = snap.data();
        this.updateState({ data, loading: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubcribe) {
      this.unsubcribe();
    }
  }

  updateState = (obj, callback) => {
    this.setState(obj, callback);
  };

  onDelete = (index) => {
    let { data } = this.state;
    data.splice(index, 1);
    this.updateState({ data });
  };

  onAddNew = () => {
    let { data } = this.state;
    data.push({ title: "", content: "" });
    this.updateState({ data });
  };

  updateData = (key, index, e) => {
    let { data } = this.state;
    data[index][key] = e.target.value;
    this.updateState({ data });
  };

  save = async () => {
    let { data } = this.state;
    this.showLoader();
    if (data && data.length > 0) {
      await this.ref.set({ data }, { merge: true });
      this.showSuccess("Faq updated");
    } else {
      this.showError("Please enter some value");
    }
    this.hideLoader();
  };

  renderData = (item, index) => {
    let { title, content } = item;
    let idTitle = `title_${index}`;
    let idContent = `content_${index}`;
    return (
      <div key={`item_${index}`} className="card">
        <div className="card-content">
          <p className="text-right">
            <i
              data-position="top"
              data-tooltip="Delete"
              className="tooltipped material-icons pointer"
              onClick={this.onDelete.bind(this, index)}
            >
              delete
            </i>
          </p>
          <div className="input-field">
            <input
              value={title}
              id={idTitle}
              type="text"
              className="validate"
              onChange={this.updateData.bind(this, "title", index)}
            />
            <label className={title ? "active" : ""} htmlFor={idTitle}>
              Title Text *
            </label>
          </div>
          <div className="input-field m-t-20">
            <input
              value={content}
              id={idContent}
              type="text"
              className="validate"
              onChange={this.updateData.bind(this, "content", index)}
            />
            <label className={content ? "active" : ""} htmlFor={idContent}>
              Content Text *
            </label>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { data } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col s8">
            <h4>FAQ</h4>
          </div>
          <div className="col s4 text-right">
            <button
              type="button"
              className="btn btn-primary m-r-20"
              onClick={this.onAddNew}
            >
              Add New
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.save}
            >
              Save
            </button>
          </div>
        </div>
        <div className="m-t-20">
          {data.map((item, index) => {
            return this.renderData(item, index);
          })}
        </div>
      </div>
    );
  }
}

export default FAQ;
